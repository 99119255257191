import React from 'react';

import { useTheme } from '../../../../context/ThemeProvider';

interface Props {
  isLiked: boolean;
  like: (arg0: boolean) => void;
}

const LikeButton: React.FC<Props> = ({ isLiked, like }) => {
  const { colors } = useTheme();
  const HeartSvg = React.useCallback(
    (fill: string, stroke: string) => (
      <svg width="22.36" height="20.114" viewBox="0 0 22.36 20.114">
        <path
          id="_001-heart"
          data-name="001-heart"
          d="M18.745,1.773A5.475,5.475,0,0,0,14.672,0a5.122,5.122,0,0,0-3.2,1.1,6.545,6.545,0,0,0-1.293,1.35A6.541,6.541,0,0,0,8.886,1.1,5.121,5.121,0,0,0,5.688,0,5.475,5.475,0,0,0,1.615,1.773,6.364,6.364,0,0,0,0,6.119a7.578,7.578,0,0,0,2.019,4.96,43.058,43.058,0,0,0,5.055,4.745c.7.6,1.494,1.274,2.319,1.994a1.2,1.2,0,0,0,1.574,0c.824-.721,1.619-1.4,2.319-2a43.038,43.038,0,0,0,5.055-4.744,7.577,7.577,0,0,0,2.019-4.96A6.363,6.363,0,0,0,18.745,1.773Zm0,0"
          transform="translate(1 1)"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
        />
      </svg>
    ),
    [],
  );

  return (
    <div
      onClick={() => like(!isLiked)}
      className={`heart ${isLiked ? 'active' : ''}`}>
      {HeartSvg('transparent', colors.TEXT2)}
      {HeartSvg(colors.DANGER, colors.DANGER)}
    </div>
  );
};

export default LikeButton;
