import './styles.scss';

import React from 'react';

import { Typography } from 'antd';

import { durationFormat } from '../../../shared/utils';
import { Chapter } from '../../../types/courseTypes';
import SectionChapterItem from './SectionChapterItem';

interface Props {
  index: number;
  title: string;
  moduleTerminology?: string;
  chapters: Chapter[];
  noBorder?: boolean;
  isLocked?: boolean;
  onChapterClick?: (chapterId: string) => void;
  showProgress?: boolean;
  purchaseRequired?: boolean;
}

const SectionModule: React.FC<Props> = ({
  index,
  title,
  moduleTerminology,
  noBorder,
  chapters,
  isLocked,
  onChapterClick,
  showProgress,
  purchaseRequired,
}) => {
  const getExtraText = (chapter: Chapter) => {
    const res = [];
    if (chapter.contentType) {
      if (chapter.contentType === 'iframe') {
        res.push('Link');
      } else {
        res.push(chapter.contentType.toTitleCase());
      }
    }
    if (chapter.totalDuration)
      res.push(durationFormat(chapter.totalDuration || 0));
    if (chapter.additionalContent?.length > 0)
      res.push(`Resources (${chapter.additionalContent.length})`);
    return res;
  };

  return (
    <div
      className="section-accordion"
      style={noBorder ? { borderBottomWidth: 0 } : {}}>
      <div className="section-accordion__title">
        <Typography.Title
          ellipsis={{
            rows: 2,
            expandable: false,
          }}
          level={5}
          className="section-accordion__title__pill">
          {moduleTerminology || `Section ${index}`}
        </Typography.Title>
        <Typography.Title
          ellipsis={{
            rows: 2,
            expandable: false,
          }}
          level={5}
          className="section-accordion__title__text">
          {title}
        </Typography.Title>
      </div>
      <div className="section-accordion__content">
        {chapters.map((chapter, i) => (
          <SectionChapterItem
            key={chapter._id}
            chpaterId={chapter._id}
            title={chapter.title || ''}
            index={i + 1}
            extraText={getExtraText(chapter)}
            onClick={() => onChapterClick?.(chapter._id)}
            contentType={chapter.contentType}
            progress={chapter.progress}
            showProgress={showProgress}
            isLocked={isLocked}
            purchaseRequired={purchaseRequired}
            isPreviewAvailable={chapter.isFreeChapter}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(SectionModule);
