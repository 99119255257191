import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Form,
  Input,
  message,
} from 'antd';

import API from '../../api';
import UserAPI from '../../api/UserAPI';
import tmlogo from '../../assets/svg/tmlogo.svg';
import Loader from '../../components/Loader';
import { setUserDetails } from '../../context/userReducer';
import showAppError from '../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import {
  emailRegex,
  rgx10digit,
} from '../../shared/utils';
import { ROUTES } from '../../types/routes';
import { IUser } from '../../types/userTypes';
import LoginHeader from './LoginHeader';

const SignupBox: React.FC = () => {
  const userDetails = useAppSelector((state) => state.user);
  const {
    isLoggedIn,
    isTagMango,
    hostMetadata: { logo },
    deeplinkUrl,
  } = useAppSelector((state) => state.app);
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const [isEmailLogin, setIsEmailLogin] = React.useState(false);
  const [saving, setSaving] = useState(false);
  const [countryCode, setCountryCode] = useState('IN');
  const [countryLoading, setCountryLoading] = useState(false);

  const savePersonalDetails = async (values: any) => {
    if (!values) return;
    let { name, phoneOrMail: email } = values;
    name = name?.toString().trim();
    email = email?.toString().trim();
    if (!name || !name.length) {
      message.error('Please enter your name');
      return;
    }
    if (!email || !email.length) {
      message.error(
        isEmailLogin ? 'Please enter phone number' : 'Please enter email ID',
      );
      return;
    }
    if (isEmailLogin) {
      if (
        (userDetails.country
          ? userDetails.country === 'IN'
          : countryCode === 'IN') &&
        !rgx10digit.test(email)
      ) {
        message.error('Please enter a 10 digit phone number');
        return;
      } else if (
        !/^[0-9]*$/.test(email) ||
        email.length > 10 ||
        email.length < 7
      ) {
        message.error('Please enter a valid phone number (7-10 digits)');
        return;
      }
    } else if (!emailRegex.test(email)) {
      message.error('Please enter valid email Id');
      return;
    }

    setSaving(true);
    UserAPI.savePersonalDetails(name, isEmailLogin, email)
      .then((res) => {
        const data: Partial<IUser> = {
          id: '',
          name,
          userSlug: '',
          type: 'details_added',
          showInbox: false,
          approveStatus: 'notrequested',
          isEmailVerified: res.data.result.isEmailVerified,
          requiresPostMigrationVerification:
            res.data.result.requiresPostMigrationVerification,
        };
        if (isEmailLogin) {
          data.phone = email;
        } else {
          data.email = email;
        }
        dispatch(setUserDetails(data));
        navigate(
          ROUTES.FEED,
          {},
          {
            reset: true,
          },
        );
      })
      .catch((err: any) => {
        showAppError(err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const getCountry = async () => {
    setCountryLoading(true);
    const response = await API.getCurrentCountry();
    if (response.data.code === 0) {
      const data = response?.data?.result;
      setCountryCode(JSON.parse(data as string)?.countryCode);
    }
    setCountryLoading(false);
  };

  useEffect(() => {
    getCountry();
    if (isLoggedIn && userDetails.type === 'started') {
      if (userDetails.email && userDetails.email.length > 0) {
        setIsEmailLogin(true);
      }
    } else if (!isLoggedIn) {
      navigate(ROUTES.LOGIN, {}, { reset: true });
    } else {
      navigate(ROUTES.FEED, {}, { reset: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return saving || countryLoading ? (
    <Loader />
  ) : (
    <div className="loginBox">
      <LoginHeader
        title="Welcome! First things first..."
        subtitle="You can always change them later."
        logo={isTagMango ? tmlogo : logo}
      />

      <Form
        layout="vertical"
        className="loginForm"
        onFinish={savePersonalDetails}>
        <Form.Item required requiredMark name="name">
          <Input placeholder="First name" className="loginInput" />
        </Form.Item>
        <Form.Item required requiredMark name="phoneOrMail">
          <Input
            type={isEmailLogin ? 'tel' : 'email'}
            placeholder={isEmailLogin ? 'Enter mobile' : 'Enter email'}
            className="loginInput"
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          className="loginFormSubmitBtn">
          Continue
        </Button>
      </Form>

      <div className="loginBoxFooter">
        <div className="tmSignupAgree">
          By clicking <span>‘Continue’</span> you are agreeing to our{' '}
          <a href={deeplinkUrl + 'terms'}>Terms of Use</a> and{' '}
          <a href={deeplinkUrl + 'privacy'}>Privacy Policy</a>.
        </div>
      </div>
    </div>
  );
};

export default SignupBox;
