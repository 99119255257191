import React, {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  message,
  Tooltip,
  Typography,
} from 'antd';
import { MdDownload } from 'react-icons/md';

import CourseAPI from '../../api/CourseAPI';
import { useTheme } from '../../context/ThemeProvider';
import showAppError from '../../shared/error';

interface ICertificateState {
  certificateId: string;
  certificateReceivedOn?: string;
  certificateStatus?: 'ongoing' | 'completed' | null;
}

const initialState: ICertificateState = {
  certificateId: '',
  certificateReceivedOn: '',
  certificateStatus: null,
};

const MESSAGE_DURATION = 1; // in seconds

const MESSAGES = {
  ONGOING:
    'Certificate generation is in progress. It will be mailed to you and will also be available for download shortly.',
  ERROR: 'Failed to generate course completion certificate',
  NOT_AVAILABLE: 'Certificate is not published or not available yet',
  DOWNLOADING: 'Downloading certificate...',
  INITIATED:
    'Certificate generation initiated successfully! It will be mailed to you and will also be available for download shortly.',
};

interface Props {
  type?: 'button' | 'text';
  color?: string;
  courseId: string;
  courseProgressId: string;
  certificateId: string;
  certificateUrl?: string;
  certificateReceivedOn?: string;
  certificateStatus?: 'ongoing' | 'completed';
  className?: string;
  onReload?: () => void;
  hidden?: boolean;
}

const DownloadCertificateButton: React.FC<Props> = ({
  type = 'button',
  color,
  courseId,
  courseProgressId,
  certificateId,
  certificateUrl,
  certificateReceivedOn,
  certificateStatus,
  className,
  onReload,
  hidden,
}) => {
  const { colors: COLORS } = useTheme();

  const [state, setState] = useState<ICertificateState>(initialState);
  const [isGeneratingCertificate, setIsGeneratingCertificate] = useState(false);

  const generateCertificate = async () => {
    if (state.certificateStatus === 'ongoing') {
      onReload?.();
      message.info(MESSAGES.ONGOING, MESSAGE_DURATION);
      return;
    }

    if (state.certificateStatus === 'completed' && certificateUrl) {
      const hideLoadingStatus = message.loading(
        MESSAGES.DOWNLOADING,
        MESSAGE_DURATION,
      );

      setTimeout(() => {
        hideLoadingStatus();
        window.open(certificateUrl, '_blank');
      }, MESSAGE_DURATION * 1000); // converting seconds to milliseconds

      return;
    }

    setIsGeneratingCertificate(true);

    try {
      const res = await CourseAPI.sendCertificateMail(
        courseId,
        courseProgressId,
      );
      if (res.status === 200) {
        if (res.data.result === 'nocertificate')
          message.warning(MESSAGES.NOT_AVAILABLE);
        else {
          message.info(MESSAGES.INITIATED);
          setState((prevState) => ({
            ...prevState,
            certificateStatus: 'ongoing',
          }));
          onReload?.();
        }
      } else {
        showAppError(res.data, MESSAGES.ERROR);
      }
    } catch (err) {
      showAppError(err, MESSAGES.ERROR);
    } finally {
      setIsGeneratingCertificate(false);
    }
  };

  useEffect(() => {
    if (certificateId) {
      setState((prevState) => ({
        ...prevState,
        certificateId,
        certificateReceivedOn,
        certificateStatus,
      }));
    }
  }, [certificateId, certificateReceivedOn, certificateStatus]);

  const buttonText = useMemo(() => {
    return state.certificateStatus === 'completed' && certificateUrl
      ? 'Download certificate'
      : 'Generate certificate';
  }, [certificateUrl, state.certificateStatus]);

  return hidden ? null : (
    <>
      {type === 'button' ? (
        <Tooltip
          title={
            state.certificateStatus === 'ongoing'
              ? MESSAGES.ONGOING
              : buttonText
          }>
          <span>
            <Button
              className="siteBtn noStyle square-btn"
              onClick={generateCertificate}
              loading={isGeneratingCertificate}
              disabled={isGeneratingCertificate}>
              <MdDownload />
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Typography.Title
          level={4}
          className={className || 'siteBtn siteBtnLink accentdark'}
          onClick={generateCertificate}
          style={{
            color:
              certificateStatus === 'completed'
                ? color || COLORS.PRIMARY
                : COLORS.LINK,
          }}>
          {buttonText}
        </Typography.Title>
      )}
    </>
  );
};

export default memo(DownloadCertificateButton);
