import './CustomImagePreview.scss';

import React, { memo, useEffect, useRef } from 'react';

import { Image } from 'antd';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  fileUrl: string;
  height?: number;
  wrapperStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  blurBgStyle?: React.CSSProperties;
  blurBgImageStyle?: React.CSSProperties;
  showBlurBgImage?: boolean;
}

const CustomImagePreview: React.FC<Props> = ({
  fileUrl,
  className = '',
  height = 60,
  wrapperStyle,
  imageStyle,
  blurBgStyle,
  blurBgImageStyle,
  showBlurBgImage = false,
  ...restProps
}) => {
  const previewContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (previewContainerRef.current) {
      const imgElement = previewContainerRef.current.querySelector(
        '.custom-image-preview > img',
      ) as HTMLImageElement;
      const antImgElement = previewContainerRef.current.querySelector(
        '.custom-image-preview > .ant-image .ant-image-img',
      ) as HTMLImageElement;

      if (imgElement) {
        imgElement.style.height = `${height}px`;
      }

      if (antImgElement) {
        antImgElement.style.height = `${height}px`;
      }
    }
  }, [height]);

  return (
    <div
      ref={previewContainerRef}
      className={`custom-image-preview ${className}`}
      style={wrapperStyle}
      {...restProps}
    >
      <Image
        src={fileUrl}
        className="custom-image-preview__image"
        style={imageStyle}
      />

      {showBlurBgImage ? (
        <div className="custom-image-preview__blur-bg" style={blurBgStyle}>
          <img
            src={fileUrl}
            alt="post-input-file-preview__blur-bg__image"
            style={blurBgImageStyle}
          />
        </div>
      ) : null}
    </div>
  );
};

export default memo(CustomImagePreview);
