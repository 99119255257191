import React, { memo, useMemo } from 'react';

import { Typography } from 'antd';
import { BsPinFill } from 'react-icons/bs';

import { updatePost } from '../../../../context/activityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import { checkForPermissions } from '../../../../context/userReducer';
import { DELETED_POST_MESSAGE } from '../../../../shared/constants';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { ROUTES } from '../../../../types/routes';
import { UAMPermissions } from '../../../../types/userTypes';
import PostContent from './PostContent';
import PostHeader from './PostHeader';
import PostMedia from './PostMedia';
import PostPoll from './PostPoll';
import PostStats from './PostStats';
import PostTags from './PostTags';

type Props = {
  post: any;
  index: number;
  onCommentsPressed?: () => void;
  isRepost?: boolean;
  allowRedirectToUserProfile?: boolean;
};

const Post: React.FC<Props> = ({
  post,
  index,
  onCommentsPressed,
  isRepost,
  allowRedirectToUserProfile = true,
}) => {
  const navigate = useAppNavigate();

  const { colors } = useTheme();

  const dispatch = useAppDispatch();

  const { hostMetadata, isTagMango, isCommunityEnabled } = useAppSelector(
    (state) => state.app,
  );
  const userDetails = useAppSelector((state) => state.user);
  const { isUserProfilePage } = useAppSelector((state) => state.activity);

  const hasCreatePostAccess: boolean = useMemo(
    () =>
      Boolean(
        isTagMango
          ? userDetails.type === 'creator_completed' ||
              userDetails.type === 'dual'
          : userDetails.id === hostMetadata.creator._id,
      ) ||
      (userDetails.type === 'creator_restricted' &&
        checkForPermissions(
          [UAMPermissions.FEED_WRITE],
          userDetails.userAccessPermissions,
        )) ||
      Boolean(
        userDetails.type === 'fan_completed' &&
          isCommunityEnabled &&
          !userDetails.isBlockedFromCommunityEngagement,
      ),
    [
      hostMetadata.creator._id,
      isCommunityEnabled,
      isTagMango,
      userDetails.id,
      userDetails.isBlockedFromCommunityEngagement,
      userDetails.type,
      userDetails.userAccessPermissions,
    ],
  );

  const postRefDetails = useMemo(() => {
    return post?.postRef;
  }, [post]);

  const hideRepostOption = useMemo(() => {
    // Hide repost option if post is a repost, the video is still in the cooking state
    if (post.contentType === 'video' && !post.isCompressDone) {
      return true;
    }

    return (
      isTagMango ||
      (post.poll && post.contentType === 'poll') ||
      isUserProfilePage ||
      post?.postRef?.isDeleted
    );
  }, [isTagMango, isUserProfilePage, post]);

  return (
    <>
      {post ? (
        <div
          data-post-id={post._id} // Add this for IntersectionObserver
          data-post-creator={post.creatorId} // Add this for IntersectionObserver
          className="creatorPostsWrapper feedPost post-item"
          style={post.isDeleted ? { paddingBottom: 3 } : {}}>
          {post.isDeleted ? (
            <PostContent
              caption={DELETED_POST_MESSAGE}
              maxLines={5}
              onCaptionPressed={() => {}}
              style={{ fontWeight: 600 }}
            />
          ) : (
            <>
              {post.isPinned && !isRepost ? (
                <div className="pinnedPost">
                  <BsPinFill color={colors.ICON} size={15} />
                  <Typography.Text>Pinned Post</Typography.Text>
                </div>
              ) : null}

              {isRepost ? null : <PostTags tags={post.tags || []} />}

              <PostHeader
                post={post}
                mode={isRepost ? 'details' : 'activity'}
                allowRedirectToUserProfile={allowRedirectToUserProfile}
              />

              {post.caption ? (
                <PostContent
                  caption={post.caption}
                  maxLines={post.contentUrl ? 2 : 5}
                  onCaptionPressed={
                    isRepost
                      ? () => {}
                      : onCommentsPressed
                      ? onCommentsPressed
                      : () => {
                          navigate(ROUTES.POST_DETAILS, {
                            postId: post._id,
                          });
                        }
                  }
                />
              ) : null}

              {(post.contentUrl ||
                (!post.isCompressDone &&
                  ['image', 'audio', 'video'].includes(post.contentType))) && (
                <PostMedia
                  isDrmEnabled={post.isDrmEnabled}
                  url={post.contentUrl}
                  type={post.contentType}
                  vdoCipherId={post.videoCipherId}
                  id={post._id}
                  isCompressDone={post?.isCompressDone}
                  index={index}
                  thumbnail={post.thumbnail}
                  compressedUrl={post.compressedVideoUrl}
                  dashUrl={post.compressedVideoUrlDash}
                  hlsUrl={post.compressedVideoUrlHls}
                  contentId={post.qencodePostId}
                  hasError={post.isCompressError}
                  errorDescription={post.compressErrorDescription}
                />
              )}

              {post.poll && post.contentType === 'poll' ? (
                <PostPoll
                  pollId={post.poll._id}
                  isOwnPost={post.creatorId === userDetails.id}
                  isAnswered={post.poll.hasVoted}
                  answerOption={post.poll.voteDetails?.pollOption}
                  question={post.poll.question}
                  options={post.poll.pollOptions}
                  individualVotes={post.poll.voteCounts}
                  totalVotes={post.poll.totalVotes}
                  endDate={post.poll.pollEndAt}
                  updatePost={(newPoll: any) => {
                    dispatch(
                      updatePost({
                        ...post,
                        poll: newPoll,
                      }),
                    );
                  }}
                />
              ) : null}

              {postRefDetails?._id ? (
                <div className="creatorPostsWrapper__repost-card">
                  <Post
                    key={postRefDetails?._id}
                    index={0}
                    post={postRefDetails}
                    isRepost={Boolean(postRefDetails?._id)}
                  />
                </div>
              ) : null}

              {isRepost ? null : (
                <PostStats
                  post={post}
                  hasCreatePostAccess={hasCreatePostAccess}
                  onCommentsPressed={
                    isRepost
                      ? () => {}
                      : onCommentsPressed
                      ? onCommentsPressed
                      : () => {
                          navigate(ROUTES.POST_DETAILS, {
                            postId: post._id,
                          });
                        }
                  }
                  onRepostPressed={() =>
                    navigate(
                      ROUTES.CREATE_POST,
                      { postRefId: post?.postRef?._id || post._id }, // If reposted post is getting reposted, then postRefId and postRefDetails will be the of original post which is being reposted in the first place
                      { state: { postRefDetails: post?.postRef || post } },
                    )
                  }
                  hideRepostOption={hideRepostOption}
                />
              )}
            </>
          )}
        </div>
      ) : null}
    </>
  );
};

export default memo(Post);
