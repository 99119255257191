/* eslint-disable no-param-reassign */
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IMango } from '../types/feedTypes';

interface IMangoSliceState {
  creatorMangoes: IMango[];
  creatorFreeMangoes: IMango[];
  allCreatorMangoes: IMango[];
  subscribedMangoes: IMango[];
}

const initialState: IMangoSliceState = {
  creatorMangoes: [],
  creatorFreeMangoes: [],
  allCreatorMangoes: [],
  subscribedMangoes: [],
};

const mangoSlice = createSlice({
  name: 'mango',
  initialState,
  reducers: {
    setCreatorMangoes: (state, action: PayloadAction<IMango[]>) => {
      const newList = action.payload.map((item) => ({
        ...item,
      }));
      state.creatorMangoes = newList;
      state.allCreatorMangoes = [
        ...state.creatorFreeMangoes,
        ...state.creatorMangoes,
      ] as IMango[];
    },
    setCreatorFreeMangoes: (state, action: PayloadAction<IMango[]>) => {
      const newList = action.payload.map((item) => ({
        ...item,
      }));
      state.creatorFreeMangoes = newList;
      state.allCreatorMangoes = [
        ...state.creatorFreeMangoes,
        ...state.creatorMangoes,
      ] as IMango[];
    },
    setAllCreatorMangoes: (state, action: PayloadAction<IMango[]>) => {
      const newList = action.payload.map((item) => ({
        ...item,
      }));
      state.allCreatorMangoes = newList;
    },
    setSubscribedMangoes: (state, action: PayloadAction<IMango[]>) => {
      state.subscribedMangoes = [...action.payload];
    },
  },
});

export const {
  setCreatorMangoes,
  setCreatorFreeMangoes,
  setAllCreatorMangoes,
  setSubscribedMangoes,
} = mangoSlice.actions;

export default mangoSlice.reducer;
