/* eslint-disable @typescript-eslint/no-use-before-define */
import { Popover } from 'antd';

import CurrencySymbol from '../components/CurrencySymbol/CurrencySymbol';
import { store } from '../context/store';
import { CurrencyType } from '../types/baseTypes';
import { newNumberFormat } from './utils';

const MAX_LIMIT_FOR_K_FORMAT = 99999;

interface NumFormatterParams {
  amount: number;
  currency?: CurrencyType;
  startLimit?: number;
  currencyIcon?: React.ReactNode;
  hideCurrency?: boolean;
  hideTooltip?: boolean;
}

/**
 * Formats a numeric amount with appropriate currency, compact representation, and tooltip.
 *
 * - If the amount is above `startLimit` and below `MAX_LIMIT_FOR_K_FORMAT`, the amount is formatted using `numFormatterInUnit`.
 * - If the amount exceeds `MAX_LIMIT_FOR_K_FORMAT`, it is formatted in a compact style (e.g., 1M for 1,000,000).
 * - Optionally displays a tooltip with the full amount.
 *
 * @param data - An object containing amount, currency, start limit, currency icon, and display options.
 * @returns A React element displaying the formatted amount, with optional currency and tooltip.
 */
export const numFormatter = (data: NumFormatterParams) => {
  const { currency: userCurrency } = store.getState().user;

  const {
    amount = 0,
    startLimit = 9999,
    currencyIcon = null,
    hideCurrency = false,
    hideTooltip = false,
  } = data;

  const currency = ((data.currency && data?.currency.toUpperCase()) ||
    userCurrency ||
    'INR') as CurrencyType;

  if (!amount) return 0;

  let numAmount: string | number = amount;
  const numFormat =
    `NUM_${currency === 'USD' ? 'US' : currency}` as LocaleFormat;

  // Return in K format only if the amount is greater than 999 and less than 99999 (99.9K) else return in exact format based on currency
  if (amount >= startLimit && amount <= MAX_LIMIT_FOR_K_FORMAT) {
    numAmount = numFormatterInUnit(amount, startLimit);
  } else {
    numAmount = newNumberFormat(
      amount,
      numFormat,
      amount > MAX_LIMIT_FOR_K_FORMAT ? 'SHORT' : 'LONG',
      startLimit,
    );
  }

  const actualAmount = newNumberFormat(amount, numFormat, 'LONG', 9999);

  const formattedAmount = (
    <>
      {hideCurrency
        ? null
        : currencyIcon ?? <CurrencySymbol currency={currency} />}
      {numAmount}
    </>
  );
  const formattedActualAmount = (
    <>
      {hideCurrency
        ? null
        : currencyIcon ?? <CurrencySymbol currency={currency} />}
      {actualAmount}
    </>
  );

  if (hideTooltip) return formattedAmount;

  return (
    <Popover content={formattedActualAmount}>
      <span>{formattedAmount}</span>
    </Popover>
  );
};

type FormatType = 'amount' | 'fileSize';

interface LookupItem {
  value: number;
  symbol: string;
}

/**
 * Formats a number in a compact representation using units such as K, M, or GB.
 *
 * - Supports both `amount` and `fileSize` types, each with different units.
 * - The function will format the number to 2 decimal places by default and add the appropriate unit symbol.
 *
 * @param num - The number to be formatted.
 * @param startLimit - Minimum threshold for applying compact notation (default is 999).
 * @param digits - Number of decimal places to display (default is 2).
 * @param type - The type of formatting, either 'amount' or 'fileSize', which changes the units used.
 *
 * @returns A string with the formatted number and its unit symbol.
 */
export const numFormatterInUnit = (
  num: number,
  startLimit: number = 999,
  digits: number = 2,
  type: FormatType = 'amount',
): string => {
  try {
    if (!num) {
      return '0';
    }

    if (num <= startLimit) {
      return parseFloat(num.toFixed(digits)).toString();
    }

    const amountLookup: LookupItem[] = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
    ];

    const fileSizeLookup: LookupItem[] = [
      { value: 1, symbol: 'B' },
      { value: 1e3, symbol: 'KB' },
      { value: 1e6, symbol: 'MB' },
      { value: 1e9, symbol: 'GB' },
      { value: 1e12, symbol: 'TB' },
      { value: 1e15, symbol: 'PB' },
      { value: 1e18, symbol: 'EB' },
    ];

    const lookup = type === 'fileSize' ? fileSizeLookup : amountLookup;
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
      .slice()
      .reverse()
      .find((i) => num >= i.value);

    return item
      ? parseFloat((num / item.value).toFixed(digits).replace(rx, '$1')) +
          item.symbol
      : '0';
  } catch {
    return num.toString();
  }
};

/* eslint-disable no-param-reassign */

/**
 * Formats a number based on locale and display style.
 *
 * @param num - The actual number to be formatted.
 * @param format - Locale format for number (e.g., 'NUM_INR' for Indian style, 'NUM_US' for US style). Default is 'NUM_INR'.
 * @param digits - Format style for number display. 'SHORT' shows compact notation (e.g., 3.4M), 'LONG' shows full number with commas (e.g., 3,400,000). Default is 'LONG'.
 * @param startLimit - Threshold number up to which the full number is displayed without formatting. Default is 9999.
 *
 * @returns A string representing the formatted number with appropriate locale and style.
 *
 * @example
 * formatNumberWithLocale(earnings, 'NUM_INR', 'SHORT')
 * // Returns a number with Indian-styled commas in short notation if applicable
 */
type LocaleFormat = 'NUM_INR' | 'NUM_US' | 'NUM_EUR';
type DisplayStyle = 'SHORT' | 'LONG';

export const numFormatterInLocale = (
  num: number,
  format: LocaleFormat = 'NUM_INR',
  digits: DisplayStyle = 'LONG',
  startLimit: number = 9999,
): string => {
  if (!num) return '0';

  // Ensures number is rounded to 2 decimal places if it's a float
  if (!Number.isInteger(num)) {
    num = Number(parseFloat(num.toFixed(2)));
  }

  // Return number as-is if it's below the startLimit
  if (num <= startLimit) return num.toString();

  // Locale and formatting options based on input parameters
  const localeMap: Record<LocaleFormat, string> = {
    NUM_INR: 'en-IN',
    NUM_US: 'en-US',
    NUM_EUR: 'de-DE',
  };

  const formatOptions: Record<DisplayStyle, Intl.NumberFormatOptions> = {
    LONG: {
      notation: 'standard',
      maximumFractionDigits: 2,
    },
    SHORT: {
      notation: 'compact',
      maximumFractionDigits: 2,
    },
  };

  // Configure the number formatter based on locale and display style
  const numberFormatter = new Intl.NumberFormat(
    localeMap[format],
    formatOptions[digits],
  );

  // Format the number according to the specified locale and style
  return numberFormatter.format(num);
};
