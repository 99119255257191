import './visualzer.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from 'antd';
import {
  MdOutlineForward10,
  MdOutlinePauseCircleFilled,
  MdOutlinePlayCircleFilled,
  MdOutlineReplay10,
} from 'react-icons/md';

import audioWave from '../../assets/img/audio-wave.jpg';
import { updateMedia } from '../../context/mediaReducer';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { getModifiedURL } from '../../shared/utils';
import Bar from './AudioBar';
import VisualDemo from './VisualDemo';

export function minTwoDigits(num: number) {
  return (num < 10 ? '0' : '') + num;
}

interface Props {
  url: string;
  id: string;
  onProgress?: (progress: number, duration: number) => void;
  onEnded?: () => void;
  initalProgress?: number;
}

interface IState {
  audioData: any;
  audioControls: {
    songPercent: number;
    songTime: string;
    songDuration: string;
    playbackRate: number;
  };
  volumeLevel: number;
  isPlaying: boolean;
}

const AudioPlayer: React.FC<Props> = ({
  url,
  id,
  onProgress,
  onEnded,
  initalProgress,
}) => {
  const { mDeeplinkUrl } = useAppSelector((state) => state.app);
  const { media } = useAppSelector((state) => state.media);
  const dispatch = useAppDispatch();

  const reactAudioPlayer = useRef<HTMLAudioElement>();
  const wakeLock = useRef<WakeLockSentinel | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [state, setState] = useState<IState>({
    audioData: null,
    audioControls: {
      songPercent: 0,
      songTime: '0:00',
      songDuration: '',
      playbackRate: 1,
    },
    volumeLevel: 80,
    isPlaying: false,
  });

  const frequencyBandArray = [...Array(30).keys()];

  function setAnalyser() {
    if (!reactAudioPlayer.current) return;
    const audioContext = new AudioContext();
    const source = audioContext.createMediaElementSource(
      reactAudioPlayer.current,
    );
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 64;
    source.connect(analyser);
    analyser.connect(audioContext.destination);
    setState((prev) => ({
      ...prev,
      audioData: analyser,
    }));
  }
  function playSong() {
    if (!state.audioData) setAnalyser();
    if (url && reactAudioPlayer.current) {
      let list = [...(document.getElementsByTagName('audio') || [])];
      list = [...list, ...(document.getElementsByTagName('video') || [])];
      list.forEach((element) => {
        if (element.src !== url) element.pause();
      });
      reactAudioPlayer.current.play();

      if ('wakeLock' in navigator) {
        navigator.wakeLock
          .request('screen')
          .then((wl) => {
            wakeLock.current = wl;
            console.log('wakelock acquired', wl);
          })
          .catch((err) => {
            console.log('wakelock error', err);
          });
      }

      setState((prev) => ({ ...prev, isPlaying: true }));
      dispatch(
        updateMedia({
          mediaId: id,
          mediaType: 'audio',
          playerState: 'playing',
        }),
      );
    }
  }

  const getFrequencyData = (styleAdjuster: (arg0: Uint8Array) => void) => {
    if (state.audioData) {
      const bufferLength = state.audioData.frequencyBinCount;
      const amplitudeArray = new Uint8Array(bufferLength);
      state.audioData.getByteFrequencyData(amplitudeArray);
      styleAdjuster(amplitudeArray);
    }
  };

  function onTimeUpdateListener() {
    if (reactAudioPlayer.current) {
      const { currentTime } = reactAudioPlayer.current;
      const currentDuration = reactAudioPlayer.current.duration;
      const percent = currentTime / currentDuration;
      const audioControls = { ...state.audioControls };

      if (typeof onProgress === 'function' && !Number.isNaN(percent)) {
        onProgress(currentTime, currentDuration);
      }

      if (Number.isNaN(percent)) {
        audioControls.songPercent = 0;
      } else {
        audioControls.songPercent = percent;
        const formattedCurrentTime = Math.floor(currentTime);
        const formattedCurrentDuration = Math.floor(currentDuration);
        audioControls.songTime = `${Math.floor(formattedCurrentTime / 60)}:${
          formattedCurrentTime % 60
            ? minTwoDigits(formattedCurrentTime % 60)
            : '00'
        }`;
        audioControls.songDuration = `${Math.floor(
          formattedCurrentDuration / 60,
        )}:${
          formattedCurrentDuration % 60
            ? minTwoDigits(formattedCurrentDuration % 60)
            : '00'
        }`;
      }
      setState((prev) => ({ ...prev, audioControls }));
    }
  }

  function setInitDuration() {
    if (reactAudioPlayer.current) {
      const currentDuration = Math.floor(reactAudioPlayer.current.duration);
      const audioControls = { ...state.audioControls };
      audioControls.songDuration = `${Math.floor(currentDuration / 60)}:${
        currentDuration % 60 ? minTwoDigits(currentDuration % 60) : '00'
      }`;
      if (initalProgress) {
        if (
          initalProgress === 0 ||
          initalProgress === 100 ||
          initalProgress > 100
        ) {
          return false;
        }
        const startTime = Math.floor((initalProgress * currentDuration) / 100);
        reactAudioPlayer.current.currentTime = startTime;
        audioControls.songTime = `${Math.floor(startTime / 60)}:${
          startTime % 60 ? minTwoDigits(startTime % 60) : '00'
        }`;
      }
      setState((prev) => ({ ...prev, audioControls }));
    }
  }

  function pauseSong() {
    if (media.mediaId === id)
      dispatch(
        updateMedia({
          mediaId: id,
          mediaType: 'audio',
          playerState: 'paused',
        }),
      );
    reactAudioPlayer.current?.pause();

    if (wakeLock.current) {
      wakeLock.current
        .release()
        .then(() => {
          console.log('wakelock released paused');
        })
        .catch((err) => {
          console.log('wakelock error', err);
        });
    }

    setState({ ...state, isPlaying: false });
  }

  useEffect(() => {
    if (media && media.mediaId !== id && state.isPlaying) {
      reactAudioPlayer.current?.pause();
      setState({ ...state, isPlaying: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, id]);

  function updateAudioTime(time: number) {
    if (url && reactAudioPlayer.current) {
      const currentDuration = reactAudioPlayer.current.duration;
      reactAudioPlayer.current.currentTime =
        time < 0 || time > currentDuration ? 0 : time;
      const audioControls = { ...state.audioControls };
      audioControls.songPercent =
        time < 0 || time > currentDuration ? 0 : time / currentDuration;
      onTimeUpdateListener();
      setState({ ...state, audioControls });
    }
  }

  function togglePlayState() {
    if (state.isPlaying) pauseSong();
    else playSong();
  }

  function add(val: number) {
    if (reactAudioPlayer.current)
      updateAudioTime(reactAudioPlayer.current.currentTime + val);
  }

  function updateVolumeLevel(value: number) {
    // console.log(value);
    if (!reactAudioPlayer.current) return;
    reactAudioPlayer.current.volume = value / 100;
    setState({ ...state, volumeLevel: value });
  }

  function setPlayBack(value: number) {
    if (!reactAudioPlayer.current) return;
    reactAudioPlayer.current.playbackRate = value;
    setState({
      ...state,
      audioControls: { ...state.audioControls, playbackRate: value },
    });
  }

  useEffect(() => {
    if (url && reactAudioPlayer.current) {
      reactAudioPlayer.current.volume = state.volumeLevel / 100;
      reactAudioPlayer.current.playbackRate = state.audioControls.playbackRate;
      reactAudioPlayer.current.onloadedmetadata = () => {
        setInitDuration();
      };
    }
    return () => {
      if (state.audioData) state.audioData.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reactAudioPlayer]);

  useEffect(() => {
    setState({
      audioData: null,
      audioControls: {
        songPercent: 0,
        songTime: '0:00',
        songDuration: '',
        playbackRate: 1,
      },
      volumeLevel: 80,
      isPlaying: false,
    });
    setIsHovered(false);
    return () => {
      setState({
        audioData: null,
        audioControls: {
          songPercent: 0,
          songTime: '0:00',
          songDuration: '',
          playbackRate: 1,
        },
        volumeLevel: 80,
        isPlaying: false,
      });
      setIsHovered(false);
    };
  }, []);

  const modifiedUrl = useMemo(() => {
    return getModifiedURL(mDeeplinkUrl, url);
  }, [mDeeplinkUrl, url]);

  return (
    <div
      className={`audioComponent audio ${
        isHovered || !state.isPlaying ? 'hover' : ''
      }`}
      id={id}>
      <div style={{ position: 'relative' }}>
        <div className="mediaControls">
          <Button className="side" onClick={() => add(-10)}>
            <MdOutlineReplay10 />
          </Button>
          <Button className="pausePlay" onClick={() => togglePlayState()}>
            {!state.isPlaying ? (
              <MdOutlinePlayCircleFilled />
            ) : (
              <MdOutlinePauseCircleFilled />
            )}
          </Button>
          <Button className="side" onClick={() => add(10)}>
            <MdOutlineForward10 />
          </Button>
        </div>
        <audio
          src={modifiedUrl}
          // loop
          ref={reactAudioPlayer as React.MutableRefObject<HTMLAudioElement>}
          onTimeUpdate={onTimeUpdateListener}
          preload="metadata"
          onPause={() => pauseSong()}
          crossOrigin="anonymous"
          onEnded={() => {
            if (media.mediaId === id)
              dispatch(
                updateMedia({
                  mediaId: id,
                  mediaType: 'audio',
                  playerState: 'stopped',
                }),
              );
            if (typeof onProgress === 'function' && reactAudioPlayer.current) {
              onProgress(
                reactAudioPlayer.current.duration,
                reactAudioPlayer.current.duration,
              );
            }
            if (onEnded) onEnded();
          }}
        />
        <VisualDemo
          frequencyBandArray={frequencyBandArray}
          getFrequencyData={getFrequencyData}
          isPlaying={state.isPlaying}
          id={id}
        />
        {!state.isPlaying ? (
          <img src={audioWave} alt="audio" className="audio-background" />
        ) : null}
      </div>
      <div className="audioGradient" />
      <div className="bgDark" />
      <Bar
        curTime={state.audioControls.songTime}
        curPercentage={state.audioControls.songPercent}
        duration={state.audioControls.songDuration}
        numberDuration={
          reactAudioPlayer.current ? reactAudioPlayer.current.duration : 0
        }
        onTimeUpdate={(val) => updateAudioTime(val)}
        volume={state.volumeLevel}
        setVolume={(val) => updateVolumeLevel(val)}
        playback={state.audioControls.playbackRate}
        setPlayBack={(val) => setPlayBack(val)}
        setIsHovered={(val) => setIsHovered(val)}
      />
    </div>
  );
};

export default React.memo(AudioPlayer);
