import './styles.scss';

import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Avatar,
  Badge,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import TMIcon from 'tm-icons-library';

import {
  setPostTags,
  setSelected,
} from '../../context/activityReducer';
import { useTheme } from '../../context/ThemeProvider';
import { checkForPermissions } from '../../context/userReducer';
import ManageCreators
  from '../../screens/Feed/components/ManageCreators/ManageCreators';
import {
  useAppDispatch,
  useAppSelector,
} from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import { UAMPermissions } from '../../types/userTypes';
import DynamicHeader from './DynamicHeader';

interface Props {
  showWorkspace?: boolean;
  tags?: {
    key: string;
    value: string;
    creatorId: string;
  }[];
}

const FeedHeader: React.FC<Props> = ({ showWorkspace = false, tags }) => {
  const { colors } = useTheme();

  const { isCommunityEnabled, isTagMango, hostMetadata } = useAppSelector(
    (state) => state.app,
  );
  const userDetails = useAppSelector((state) => state.user);
  const { selected, subscriptions, postTags } = useAppSelector(
    (state) => state.activity,
  );
  const dispatch = useAppDispatch();

  const [showManageCreators, setShowManageCreators] = useState(false);

  const getAvatar = useCallback(
    (url: string, index: number, name: string, s: boolean = false) => (
      <div
        key={index}
        className={`subscription__avatar ${s ? 'selected' : ''}`}
        style={{ marginLeft: index > 0 ? -12 : 0, zIndex: 4 - index }}>
        <div
          className="subscription__avatar__image"
          style={
            s
              ? {
                  borderWidth: 0,
                  width: 28,
                  height: 28,
                }
              : {}
          }>
          <Avatar src={url} alt="profile" />
        </div>
        {s && (
          <span className="subscription__avatar__text">
            {name ? name.split(' ')[0] : ''}
          </span>
        )}
      </div>
    ),
    [],
  );

  const getParticipantsGroup = useMemo(() => {
    let displayParticipants = [...subscriptions].slice(0, 3);
    if (selected && selected !== 'all' && subscriptions.length) {
      const newSubscriptions = [...subscriptions];
      const selectedSubscription = newSubscriptions.find(
        (item: any) => item.creatorId === selected,
      );
      if (selectedSubscription) {
        displayParticipants = [
          { ...selectedSubscription, selected: true },
          ...newSubscriptions.filter(
            (item: any) => item.creatorId !== selected,
          ),
        ].slice(0, 3);
      }
    }
    const participantCount = subscriptions.length;
    if (selected && selected !== 'all' && participantCount) {
      const participant = subscriptions.find((s) => s.creatorId === selected);
      return getAvatar(
        participant?.creatorId === userDetails.id
          ? userDetails.profilePic
          : participant?.profilePicUrl,
        0,
        participant?.creatorId === userDetails.id ? 'Me' : participant?.name,
        true,
      );
    }
    return (
      <>
        {displayParticipants.map((participant, index) =>
          getAvatar(
            participant?.creatorId === userDetails.id
              ? userDetails.profilePic
              : participant?.profilePicUrl,
            index,
            participant?.creatorId === userDetails.id
              ? 'Me'
              : participant?.name,
            participant?.selected,
          ),
        )}
        {participantCount - displayParticipants.length > 0 ? (
          <div className="subscription__extra-participant">
            <span className="subscription__extra-participant__text">
              +{participantCount - displayParticipants.length}
            </span>
          </div>
        ) : null}
      </>
    );
  }, [
    getAvatar,
    selected,
    subscriptions,
    userDetails.id,
    userDetails.profilePic,
  ]);

  const showAddIcon = useMemo(
    () =>
      Boolean(
        isTagMango
          ? userDetails.type === 'creator_completed' ||
              userDetails.type === 'dual'
          : userDetails.id === hostMetadata.creator._id,
      ) ||
      (userDetails.type === 'creator_restricted' &&
        checkForPermissions(
          [UAMPermissions.FEED_WRITE],
          userDetails.userAccessPermissions,
        )) ||
      (userDetails.type === 'fan_completed' &&
        isCommunityEnabled &&
        !userDetails.isBlockedFromCommunityEngagement),
    [
      hostMetadata.creator._id,
      isCommunityEnabled,
      isTagMango,
      userDetails.id,
      userDetails.isBlockedFromCommunityEngagement,
      userDetails.type,
      userDetails.userAccessPermissions,
    ],
  );

  return (
    <>
      <DynamicHeader
        path={ROUTES.FEED}
        actionItems={[
          showAddIcon && (
            <Link to={ROUTES.CREATE_POST} key="create-post">
              <div className="header--activity__action">
                <TMIcon name="add" color={colors.ICON} />
              </div>
            </Link>
          ),
        ]}>
        {showWorkspace ? (
          <div className="subscription__container">
            <div className="subscription__avatar__wrapper">
              {subscriptions.length > 0
                ? getParticipantsGroup
                : getAvatar(userDetails.profilePic || '', 0, 'Me', true)}
            </div>
            <button
              className="subscription--manage__button"
              onClick={() => {
                setShowManageCreators(true);
              }}>
              <svg width="13.627" height="13.627" viewBox="0 0 13.627 13.627">
                <path
                  id="Path_1306"
                  data-name="Path 1306"
                  d="M14.827,8.11h.1a1.7,1.7,0,1,1,0,3.407h-.049a.377.377,0,0,0-.271.632l.034.034a1.7,1.7,0,1,1-2.41,2.41l-.03-.03a.377.377,0,0,0-.636.264v.1a1.7,1.7,0,1,1-3.407,0,.4.4,0,0,0-.275-.389.367.367,0,0,0-.4.069l-.034.034a1.7,1.7,0,1,1-2.41-2.41l.03-.03a.377.377,0,0,0-.264-.636H4.7a1.7,1.7,0,0,1,0-3.407.4.4,0,0,0,.389-.275.367.367,0,0,0-.069-.4l-.034-.034A1.7,1.7,0,1,1,7.4,5.034l.03.03a.369.369,0,0,0,.407.074l.082-.017A.368.368,0,0,0,8.11,4.8V4.7a1.7,1.7,0,0,1,3.407,0v.049a.371.371,0,0,0,.229.34.367.367,0,0,0,.4-.069l.034-.034a1.7,1.7,0,1,1,2.41,2.41l-.03.03a.369.369,0,0,0-.074.407l.017.082A.368.368,0,0,0,14.827,8.11Z"
                  transform="translate(-3 -3)"
                  fill="currentColor"
                  opacity="0.3"
                />
                <path
                  id="Path_1307"
                  data-name="Path 1307"
                  d="M11.271,13.542A2.271,2.271,0,1,0,9,11.271,2.271,2.271,0,0,0,11.271,13.542Z"
                  transform="translate(-4.458 -4.458)"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
              <span className="subscription--manage__text">
                Manage Creators
              </span>
            </button>
          </div>
        ) : null}
        {tags && tags.length > 0 ? (
          <div className="tags-filter__container">
            <div className="tags-filter__text">
              <p>
                <Badge
                  dot
                  count={
                    tags.filter((tag) =>
                      postTags.map((t) => t._id).includes(tag.key),
                    ).length
                  }
                  overflowCount={9}
                  size="small"
                  style={{
                    backgroundColor: colors.PRIMARY,
                    // color: colors.BACKGROUND,
                    // marginLeft: 6,
                  }}>
                  Tags
                </Badge>
              </p>
            </div>
            <div className="tags-filter__wrapper">
              {tags
                .map(({ key, ...rest }) => ({
                  key,
                  ...rest,
                  isSelected: postTags.map((t) => t._id).includes(key),
                }))
                .map(({ key, value, creatorId, isSelected }) => (
                  <div
                    key={key}
                    onClick={() => {
                      if (isSelected) {
                        dispatch(
                          setPostTags([
                            ...postTags.filter((t) => t._id !== key),
                          ]),
                        );
                      } else {
                        dispatch(
                          setPostTags([
                            ...postTags,
                            {
                              _id: key,
                              tagName: value,
                              creator: creatorId,
                            },
                          ]),
                        );
                      }
                    }}
                    className={`tags-filter__tag ${
                      isSelected ? 'active' : ''
                    }`}>
                    <Typography.Text>{value}</Typography.Text>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </DynamicHeader>

      <ManageCreators
        showModal={showManageCreators}
        closeModal={() => setShowManageCreators(false)}
        subscriptions={subscriptions.filter((sub) => sub.creatorId)}
        userDetails={userDetails}
        selected={selected}
        setSelected={(val) => {
          dispatch(setSelected(val));
          setShowManageCreators(false);
        }}
      />
    </>
  );
};

export default React.memo(FeedHeader);
