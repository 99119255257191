import './styles.scss';

import React, { useEffect } from 'react';

import { Button, message } from 'antd';
import moment from 'moment';
import { IoCheckmarkCircle, IoHandLeft, IoTimerOutline } from 'react-icons/io5';

import FeedAPI from '../../../api/FeedAPI';
import JoinSvg from '../../../assets/svg/Join';
import SwipeableButton from '../../../components/SwipeableButton/SwipeableButton';
import { useAppProvider } from '../../../context/AppProvider';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import { useAppSelector } from '../../../shared/hooks';
import { IVideoCallsItem, IWebinar } from '../../../types/feedTypes';
import Time from './Time';

enum AttendanceStatus {
  SESSION_NOT_STARTED,
  SESSION_ACTIVE,
  LOADING,
  MARKED,
}

const UpcomingWorkshops: React.FC = () => {
  const { colors } = useTheme();
  const { onMeetingJoin } = useAppProvider();

  const { postState, selected } = useAppSelector((state) => state.activity);
  const userDetails = useAppSelector((state) => state.user);

  const [state, setState] = React.useState<IWebinar>();
  const [meetingStarted, setMeetingStarted] = React.useState(false);
  const [attendanceStatus, setAttendanceStatus] =
    React.useState<AttendanceStatus>(AttendanceStatus.SESSION_NOT_STARTED);

  const removeSessionTimer = React.useRef<NodeJS.Timeout>();

  const fetchRecentVideoCall = async () => {
    if (removeSessionTimer.current) {
      clearTimeout(removeSessionTimer.current);
    }

    try {
      const res = await FeedAPI.getCreatorWebinarsV5({
        status: 'upcoming',
        page: 1,
        limit: 10,
      });
      if (
        res.status === 200 &&
        res.data.result &&
        res.data.result.videocalls?.[0]?.length
      ) {
        const filtertedVideoCalls = (res.data.result?.videocalls?.[0] || [])
          .reduce((acc: IWebinar[], each: IVideoCallsItem) => {
            if (!each.calls) {
              return acc;
            }

            return [...acc, ...each?.calls];
          }, [])
          .filter((videoCall) =>
            moment(videoCall.fromTime).isSameOrAfter(moment(), 'day'),
          );

        // get the recent call which is scheduled for today or tomorrow
        const recentVideoCall = filtertedVideoCalls.find((videoCall) =>
          moment(videoCall.fromTime).isSameOrBefore(
            moment().add(1, 'day'),
            'day',
          ),
        );

        if (recentVideoCall) {
          setState(recentVideoCall);
        } else {
          setState(undefined);
        }
      }
    } catch (error) {
      showAppError(error);
    }
  };

  useEffect(() => {
    if (postState.refreshing) {
      fetchRecentVideoCall();
    }
  }, [postState.refreshing]);

  useEffect(() => {
    if (selected) {
      fetchRecentVideoCall();
    }
  }, [selected]);

  const timeDiffinMin = () => {
    const fromTime = state?.fromTime;

    if (fromTime) {
      const now = moment(new Date());
      const end = moment(fromTime);

      return moment.duration(end.diff(now)).asMinutes();
    }

    return 20;
  };

  const timeDiffinEnd = () => {
    const toTime = state?.toTime;

    if (toTime) {
      const now = moment(new Date());
      const end = moment(toTime);

      return moment.duration(end.diff(now)).asMinutes();
    }

    return 20;
  };

  // check if attendance session is active
  const fetchAttendanceStatus = async () => {
    if (!state) {
      return;
    }

    const errorMsg = 'Failed to check attendance status';
    setAttendanceStatus(AttendanceStatus.LOADING);

    try {
      const { data: resp } = await FeedAPI.checkWorkshopAttendanceStatus(
        state?._id,
      );
      if (resp.code === 0) {
        if (resp?.result) {
          setAttendanceStatus(AttendanceStatus.MARKED);
          removeSessionTimer.current = setTimeout(() => {
            setAttendanceStatus(AttendanceStatus.SESSION_NOT_STARTED);
          }, 30000);
        } else {
          setAttendanceStatus(AttendanceStatus.SESSION_ACTIVE);
        }
      } else {
        message.error(errorMsg);
        setAttendanceStatus(AttendanceStatus.SESSION_ACTIVE);
      }
    } catch (error) {
      message.error(errorMsg);
      setAttendanceStatus(AttendanceStatus.SESSION_ACTIVE);
    }
  };

  const onMarkAttendance = async () => {
    if (!state) {
      return;
    }
    try {
      setAttendanceStatus(AttendanceStatus.LOADING);
      const res = await FeedAPI.markAttendance(state._id);
      if (res.status === 200) {
        setAttendanceStatus(AttendanceStatus.MARKED);
        removeSessionTimer.current = setTimeout(() => {
          setAttendanceStatus(AttendanceStatus.SESSION_NOT_STARTED);
        }, 30000);
      } else {
        setAttendanceStatus(AttendanceStatus.SESSION_ACTIVE);
      }
    } catch (error) {
      setAttendanceStatus(AttendanceStatus.SESSION_ACTIVE);
      showAppError(error);
    }
  };

  useEffect(() => {
    if (state) {
      if (moment(state.fromTime).isSameOrAfter(moment())) {
        setTimeout(() => {
          setMeetingStarted(true);
          setTimeout(() => {
            fetchRecentVideoCall();
          }, 60000 * timeDiffinEnd());
        }, timeDiffinMin() * 60000);
      } else if (moment(state.fromTime).isBefore(moment())) {
        setMeetingStarted(true);
      } else {
        setMeetingStarted(false);
      }

      // check if attendance session is active
      if (state.currentAttendanceSession) {
        fetchAttendanceStatus();
      } else {
        setAttendanceStatus(AttendanceStatus.SESSION_NOT_STARTED);
      }
    }

    return () => {
      if (removeSessionTimer.current) {
        clearTimeout(removeSessionTimer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onMeetingClick = () => {
    if (state) onMeetingJoin(state);
  };

  return state ? (
    <div className="feed-card info">
      <div className="feed-card__icon-bar">
        <span className="feed-card__icon-bar__text">
          {moment(state.fromTime).isSame(moment(), 'd')
            ? 'TODAY'
            : moment(state.fromTime).format('ddd').toUpperCase()}
        </span>
      </div>
      <div
        className="feed-card__details-wrapper workshopCardFeed"
        style={{
          width: 'calc(100% - 36px)',
        }}>
        <div className="workshopCardDetails">
          <h5 className="workshopTitle">{state.title}</h5>
          <span className="time">
            {moment(state.fromTime).format('hh:mm A')} -{' '}
            {moment(state.toTime).format('hh:mm A')}
          </span>
        </div>
        <div className="workshopCardAction">
          <span className="timer">
            <IoTimerOutline
              size={18}
              color={colors.ICON}
              style={{
                marginRight: '8px',
              }}
            />
            {meetingStarted ? 'Ends' : 'Starts'} In:{' '}
            <Time time={meetingStarted ? state.toTime : state.fromTime} />
          </span>
          <Button
            className="siteBtn noStyle blueBtnV2 joinBtn"
            icon={
              <JoinSvg
                width={20}
                height={20}
                background={colors.BACKGROUND}
                foreground={colors.BLUE}
              />
            }
            onClick={onMeetingClick}>
            JOIN
          </Button>
        </div>
        {attendanceStatus !== AttendanceStatus.SESSION_NOT_STARTED &&
        state.creator !== userDetails.id &&
        userDetails.type !== 'creator_restricted' ? (
          <div className="workshopAttendanceContainer">
            {attendanceStatus === AttendanceStatus.MARKED ? (
              <div
                className="workshopAttendanceStatus"
                style={{
                  backgroundColor: `${colors.GREEN}30`,
                }}>
                {<IoCheckmarkCircle size={20} color={colors.GREEN} />}
                <span>Your attendance has been marked successfully!</span>
              </div>
            ) : (
              <SwipeableButton
                onSwipe={onMarkAttendance}
                isLoading={attendanceStatus === AttendanceStatus.LOADING}
                backgroundColor={`${colors.GREEN}30`}
                thumbColor={colors.GREEN}
                textColor={colors.TEXT3}
                thumbIcon={<IoHandLeft size={20} color={colors.BACKGROUND} />}
                text="Swipe to mark attendance"
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default React.memo(UpcomingWorkshops);
