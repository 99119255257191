import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Modal, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualized from 'easy-react-virtualized';

import FeedAPI from '../../../api/FeedAPI';
import {
  getPosts,
  setViewedPostIds,
  updatePost,
} from '../../../context/activityReducer';
import { checkForPermissions } from '../../../context/userReducer';
import {
  POST_MIN_VIEW_THRESHOLD,
  POST_MIN_VIEW_TIME,
} from '../../../shared/constants';
import { BASENAME } from '../../../shared/data';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { IPost } from '../../../types/feedTypes';
import { ROUTES } from '../../../types/routes';
import { UAMPermissions } from '../../../types/userTypes';
import PostDetailsScreen from '../../PostDetails/PostDetails';
import NoDataFound from '../components/NoDataFound/NoDataFound';
import PostItem from './PostItem/PostItem';
import PostSkeleton from './PostSkeleton';

const noPostsMessageStyle: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
  margin: '20px 0',
};

interface Props {
  userId?: string;
  isUserProfilePage?: boolean;
  showUserPosts?: boolean;
  noPostsMessage?: string;
  minimumViewTime?: number;
}

const PostsList: React.FC<Props> = ({
  userId,
  isUserProfilePage,
  showUserPosts,
  noPostsMessage,
  minimumViewTime = POST_MIN_VIEW_TIME,
}) => {
  const dispatch = useAppDispatch();

  const userDetails = useAppSelector((state) => state.user);
  const { postState, selected, viewedPostIds } = useAppSelector(
    (state) => state.activity,
  );

  const isMe = userDetails.id === selected;

  const [selectedPostId, setSelectedPostId] = useState<string | undefined>();
  const [showModal, setShowModal] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const visibilityTimers = useRef<Map<string, number>>(new Map());

  const increaseAndUpdatePostViews = async (postId: string, post: IPost) => {
    try {
      const resp = await FeedAPI.increasePostViews(postId);
      if (resp.status === 200) {
        // console.log('Post views increased:', postId);
        dispatch(
          updatePost({
            ...post,
            views: (post?.views || 0) + 1,
          }),
        );
        // add to viewed set to avoid multiple view count increase
        dispatch(setViewedPostIds(postId));
      }
    } catch (error) {
      console.log('Error while increasing post views', error);
    }
  };

  const handlePostView = (postId: string) => {
    const post = postState.posts.find((item) => item._id === postId);

    if (
      post &&
      !viewedPostIds.includes(postId) &&
      post.creatorId !== userDetails.id
    ) {
      // console.log('Post viewed:', postId);
      increaseAndUpdatePostViews(postId, post);
    }
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const now = Date.now();

    entries.forEach((entry) => {
      const postId = entry.target.getAttribute('data-post-id');

      if (!postId) return;

      if (entry.isIntersecting) {
        // console.log('Post visible:', postId);
        // Post is visible, add or update its visibility start time
        if (!visibilityTimers.current.has(postId)) {
          visibilityTimers.current.set(postId, now);
        }
      } else {
        // Post is no longer visible, check if it was visible long enough
        const startTime = visibilityTimers.current.get(postId);

        if (startTime) {
          const visibilityDuration = now - startTime;

          // console.log('Post hidden:', postId, startTime);

          if (visibilityDuration >= minimumViewTime) {
            handlePostView(postId);
          }
        }

        visibilityTimers.current.delete(postId);
      }
    });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      threshold: POST_MIN_VIEW_THRESHOLD,
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postState.posts, viewedPostIds]);

  useEffect(() => {
    const postElements = document.querySelectorAll('.post-item');

    postElements.forEach((el) => observer?.current?.observe(el));

    return () => {
      postElements.forEach((el) => observer?.current?.unobserve(el));
    };
  }, [postState.posts, viewedPostIds]);

  const loadMore = async () => {
    if (
      postState.hasMore &&
      (isUserProfilePage ? selected === userId : selected)
    ) {
      dispatch(
        getPosts({
          pageCount: postState.page,
          postList: postState.posts,
          showUserPosts,
        }),
      );
    }
  };

  useEffect(() => {
    if (showModal && selectedPostId) {
      window.history.replaceState(
        null,
        '',
        `${BASENAME}${ROUTES.POST_DETAILS.replace(':postId', selectedPostId)}`,
      );
    } else if (!isUserProfilePage) {
      window.history.replaceState(null, '', `${BASENAME}${ROUTES.FEED}`);
    }
  }, [showModal, selectedPostId, isUserProfilePage]);

  const noPostsContent = useMemo(() => {
    const title = 'Whoops! No posts found!';
    const subtitle =
      'Looks like your Creator has not put up any content yet. But don’t worry, they’ll be doing it soon! 🙌';

    if (noPostsMessage) {
      return (
        <Typography.Paragraph style={noPostsMessageStyle}>
          {noPostsMessage}
        </Typography.Paragraph>
      );
    }

    return isMe ? (
      <Typography.Paragraph style={noPostsMessageStyle}>
        Whoops! No posts found!
      </Typography.Paragraph>
    ) : (
      <NoDataFound title={title} subtitle={subtitle} />
    );
  }, [noPostsMessage, isMe]);

  return (
    <>
      <EasyVirtualized
        onLoadMore={loadMore}
        useParentScrollElement
        hasMore={postState.hasMore}>
        {postState.posts.map((item, index) => (
          <PostItem
            key={item._id}
            post={item}
            index={index}
            onCommentsPressed={() => {
              setSelectedPostId(item._id);
              setShowModal(true);
            }}
          />
        ))}

        {userDetails.type !== 'creator_restricted' ||
        checkForPermissions(
          [UAMPermissions.FEED_READ],
          userDetails.userAccessPermissions,
        ) ? (
          postState.loading || !selected || postState.hasMore ? (
            <>
              <PostSkeleton />
              <PostSkeleton />
            </>
          ) : postState.posts.length === 0 ? (
            noPostsContent
          ) : (
            <Typography.Paragraph style={noPostsMessageStyle}>
              That’s it! No more posts to show.
            </Typography.Paragraph>
          )
        ) : null}
      </EasyVirtualized>

      <Modal
        open={showModal}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose
        className="postDetailsModal">
        <PostDetailsScreen
          postId={selectedPostId}
          handleBack={() => {
            setShowModal(false);
            setSelectedPostId(undefined);
          }}
        />
      </Modal>
    </>
  );
};

export default React.memo(PostsList);
