import './styles.scss';

import React, { useMemo, useState } from 'react';

import DynamicWrapper from '../../components/DynamicWrapper/DynamicWrapper';
import DynamicHeader from '../../components/Header/DynamicHeader';
import { REFRESH_TOKEN_KEY } from '../../shared/data';
import { useAppSelector } from '../../shared/hooks';
import { objToQueryParams } from '../../shared/utils';
import { CustomApp } from '../../types/HostMetadata';
import { HomeRoutes, ROUTES } from '../../types/routes';
import CustomAppsLoader from './CustomAppsLoader';

interface Props {
  app: CustomApp;
  name: HomeRoutes;
}

const CustomAppPreview: React.FC<Props> = ({ app, name }) => {
  const { mDeeplinkUrl } = useAppSelector((state) => state.app);

  const [isCustomAppReady, setIsCustomAppReady] = useState(true);

  const appUrl = useMemo(() => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    let url = '';

    if (app.domain) {
      url += app.domain;
    } else {
      url += `${mDeeplinkUrl}web/apps`;
    }

    if (app.slug) {
      if (!url.endsWith('/')) {
        url += '/';
      }
      url += `${app.slug}`;
    }

    if (app.entryPath && app.entryPath.length > 0) {
      if (!url.endsWith('/')) {
        url += '/';
      }
      url += `${app.entryPath}`;
    }

    if (refreshToken) {
      if (url.match(/(\?|&)([^=]+)=([^&]+)/g)) {
        url += '&';
      } else {
        url += '?';
      }
      url += `refreshToken=${refreshToken}`;
    }

    if (app?.initialQueryParams) {
      url += `${url.includes('?') ? '&' : '?'}${objToQueryParams({
        ...app?.initialQueryParams,
      })}`;
    }

    return url;
  }, [app, mDeeplinkUrl]);

  if (app.target === '_blank') {
    window.open(appUrl, '_blank');
    window.location.href = ROUTES.CUSTOM_APPS;
    return null;
  }

  return (
    <DynamicWrapper path={name}>
      <div className="pageRoot">
        <DynamicHeader path={name} title={app.name} />
        <div className="pageContent">
          {isCustomAppReady ? <CustomAppsLoader /> : null}
          <iframe
            onLoad={() => setIsCustomAppReady(false)}
            title={app.name}
            src={appUrl}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
    </DynamicWrapper>
  );
};

export default React.memo(CustomAppPreview);
