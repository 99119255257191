import { message } from 'antd';
import heic2any from 'heic2any';

import API from '../api';
import showAppError from './error';

export const getFileName = (name: string): string => {
  const fullName = name.split('/').pop();
  if (fullName) {
    const split = fullName.split('-');
    split.pop();
    const ext = fullName.split('.').pop();
    return `${split.join('-')}.${ext}`;
  }
  return name;
};

// Enums for asset types
export enum AssetType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export type SingleAssetType = `${AssetType}`;

// Enums for file types
export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
}

export type SingleFileType = `${FileType}`;

export const ACCEPTED_FILE_TYPES_EXPANDED = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/gif',
  'image/webp',
  'image/tiff',
  'image/heic',
  'video/mp4',
  'video/webm',
  'video/quicktime',
  'video/mov',
  'video/mkv',
  'audio/mp3',
  'audio/mpeg',
  'audio/m4a',
  'audio/aac',
  'audio/m3u',
  'audio/oga',
  'audio/wav',
  'application/pdf',
] as const;

export type AcceptedFileTypeExpanded =
  (typeof ACCEPTED_FILE_TYPES_EXPANDED)[number];

export const ACCEPTED_IMAGE_FILE_TYPES = [
  'jpg',
  'jpeg',
  'png',
  'svg',
  'gif',
  'webp',
  'tiff',
  'heic',
] as const;

export type AcceptedImageFileType = (typeof ACCEPTED_IMAGE_FILE_TYPES)[number];

export const ACCEPTED_VIDEO_FILE_TYPES = [
  'mp4',
  'webm',
  'quicktime',
  'mov',
  'mkv',
] as const;

export type AcceptedVideoFileType = (typeof ACCEPTED_VIDEO_FILE_TYPES)[number];

export const ACCEPTED_AUDIO_FILE_TYPES = [
  'mp3',
  'mpeg',
  'm4a',
  'aac',
  'm3u',
  'oga',
  'wav',
] as const;

export type AcceptedAudioFileType = (typeof ACCEPTED_AUDIO_FILE_TYPES)[number];

// Accepted file extensions for each file type
export const ACCEPTED_FILE_TYPES = {
  [FileType.IMAGE]: ACCEPTED_IMAGE_FILE_TYPES,
  [FileType.VIDEO]: ACCEPTED_VIDEO_FILE_TYPES,
  [FileType.AUDIO]: ACCEPTED_AUDIO_FILE_TYPES,
} as const;

// Type definitions
export type AcceptedFileType = (typeof ACCEPTED_FILE_TYPES)[
  | FileType.IMAGE
  | FileType.VIDEO
  | FileType.AUDIO][number];

export interface UploadFilesOptions {
  files: File[];
  type: SingleFileType;
  assetType?: SingleAssetType; // Asset type for uploading to the server
  accept?: AcceptedFileType[]; // Defaults to the accepted types for the file type
  maxSizeUnit?: 'KB' | 'MB'; // Max file size unit, defaults to MB
  maxSize?: number; // Max file size in MB, defaults to 1GB for videos and 10MB for others
  uploadToServer?: boolean; // Whether to upload the file to the server
  errorMsg?: string; // Custom error message for validation
  // successMsg?: string; // Custom success message
  onError?: (message: string) => void; // Error callback
  uploadCallback?: (files: File[], url: string[]) => void; // Callback after uploading the files
  loadingCallback?: (loading: boolean) => void; // Callback to handle loading state
}

export interface UploadFilesHandlerOptions {
  type: SingleFileType;
  accept?: AcceptedFileType[];
  onUpload?: (files: File[], type: SingleFileType) => void;
}

// Utility functions
export const getFileExtension = (
  fileName: string,
  type: SingleFileType,
): AcceptedFileType | undefined => {
  const match = /\.([^.]+)$/.exec(fileName);
  const extension = match?.[1]?.toLowerCase();

  // Ensure the extracted extension matches the accepted file types for the given type
  if (
    extension &&
    (ACCEPTED_FILE_TYPES[type] as readonly string[]).includes(extension)
  ) {
    return extension as AcceptedFileType;
  }

  return undefined;
};

const getBase64 = (file: File, callback: (base64: string) => void): void => {
  const reader = new FileReader();
  reader.onload = () => callback(reader.result as string);
  reader.readAsDataURL(file);
};

const validateFile = (
  file: File,
  type: SingleFileType,
  accept: AcceptedFileType[],
  // eslint-disable-next-line @typescript-eslint/default-param-last
  maxSizeUnit: 'KB' | 'MB' = 'MB',
  maxSize: number,
): string | null => {
  const fileExtension = getFileExtension(file.name, type);

  if (!fileExtension || !accept.includes(fileExtension as AcceptedFileType)) {
    return `Invalid file type. Accepted types: ${accept.join(', ')}.`;
  }

  // Convert file size to the appropriate unit
  const fileSizeInUnit =
    maxSizeUnit === 'KB' ? file.size / 1024 : file.size / (1024 * 1024);

  // Compare the converted file size with maxSize
  if (fileSizeInUnit > maxSize) {
    return `File size exceeds the maximum allowed size of ${maxSize} ${maxSizeUnit}.`;
  }

  return null;
};

export const uploadFileToServerHandler = async (
  file: File,
  callback: (fileUrl: string) => void,
  assetType: 'public' | 'private' = 'public',
  errorMsg?: string,
) => {
  if (file) {
    try {
      const resp = await API.uploadFileInAssets(assetType, file);
      if (resp.status === 200) {
        const fileUrl = resp.data?.result?.files?.[0];

        callback(fileUrl);
      } else {
        showAppError(resp.data, errorMsg);
      }
    } catch (error) {
      showAppError(error, errorMsg);
    }
  }
};

export const uploadFiles = async (
  options: UploadFilesOptions,
): Promise<void> => {
  const {
    files,
    type,
    assetType = 'public',
    accept = ACCEPTED_FILE_TYPES[type],
    maxSizeUnit = 'MB',
    maxSize = type === FileType.VIDEO ? 1024 : 10, // 1GB for videos, 10MB for others
    uploadToServer = false,
    errorMsg,
    // successMsg,
    onError = (msg) => {
      message.error(msg);
    },
    uploadCallback,
    loadingCallback,
    // onSuccess = (_, __) => {
    //   message.success('File uploaded successfully!');
    // },
  } = options;

  const filesToSet: File[] = [];
  const fileUrls: string[] = [];
  const errorMsgToShow =
    errorMsg || 'An unexpected error occurred during file processing.';

  const processFile = async (singleFile: File) => {
    try {
      // Validate the file
      const validationError = validateFile(
        singleFile,
        type,
        accept as AcceptedFileType[],
        maxSizeUnit,
        maxSize,
      );

      if (validationError) {
        onError?.(validationError);
        return;
      }

      // Handle HEIC conversion for image files
      if (
        type === 'image' &&
        getFileExtension(singleFile.name, 'image') === 'heic'
      ) {
        try {
          const base64 = await new Promise<string>((resolve) => {
            getBase64(singleFile, resolve);
          });

          const heicBlob = await heic2any({
            blob: await fetch(base64).then((res) => res.blob()),
            toType: 'image/jpeg',
            quality: 0.9,
          });

          const url = URL.createObjectURL(heicBlob as Blob);
          const jpegFile = new File(
            [heicBlob as Blob],
            `${singleFile.name.split('.')[0]}.jpeg`,
            {
              type: 'image/jpeg',
            },
          );

          if (uploadToServer) {
            await uploadFileToServerHandler(
              jpegFile,
              (fileUrl) => {
                fileUrls.push(fileUrl);
              },
              assetType,
              errorMsg,
            );
          } else {
            filesToSet.push(jpegFile);
            fileUrls.push(url);
          }
          return;
        } catch (error) {
          onError?.('Failed to convert HEIC image.');
          return;
        }
      }

      // Handle other file types
      const url = URL.createObjectURL(singleFile);

      if (uploadToServer) {
        await uploadFileToServerHandler(
          singleFile,
          (fileUrl) => {
            fileUrls.push(fileUrl);
          },
          assetType,
          errorMsg,
        );
      } else {
        filesToSet.push(singleFile);
        fileUrls.push(url);
      }
    } catch (error) {
      onError?.(errorMsgToShow);
    }
  };

  let hideLoading;

  if (loadingCallback) {
    loadingCallback(true);
  } else {
    hideLoading = message.loading('Uploading...');
  }

  try {
    await Promise.all(files.map(processFile));

    uploadCallback?.(filesToSet, fileUrls);
  } catch (error) {
    onError?.(errorMsgToShow);
  } finally {
    if (loadingCallback) {
      loadingCallback(false);
    } else {
      hideLoading?.();
    }
  }
};

export const uploadFilesHandler = (
  options: UploadFilesHandlerOptions,
): void => {
  const { type, accept = ACCEPTED_FILE_TYPES[type], onUpload } = options;

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = accept.map((ext) => `.${ext}`).join(', ');

  input.addEventListener('change', (e) => {
    const target = e.target as HTMLInputElement;
    const files = target.files ? Array.from(target.files) : [];

    if (files.length > 0 && onUpload) {
      onUpload(files, type); // pass uploadCallback to handle the files in the parent component
    }
  });

  input.click();
};

export const downloadFile = async (url: string, fileName: string) => {
  const hideLoading = message.loading('Downloading...');

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName || url;
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    // Cleanup: Remove the link and revoke the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
    hideLoading();
  } catch (error) {
    console.log('Error downloading file:', error);
  }
};
