import React from 'react';

import {
  Popover,
  Typography,
} from 'antd';

import { useTheme } from '../../../context/ThemeProvider';
import { IMango } from '../../../types/feedTypes';

const overlayInnerStyle: React.CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  boxShadow: 'none',
  borderRadius: 12,
  maxWidth: 300,
  maxHeight: 300,
  overflowY: 'auto',
};

const popoverTitleStyle: React.CSSProperties = {
  marginRight: 8,
  cursor: 'pointer',
  padding: '0 4px',
  borderRadius: 4,
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: 12,
  flexShrink: 0,
};

interface Props {
  mangoes: IMango[];
  children?: React.ReactNode;
}

const MangoTitle: React.FC<Props> = ({ mangoes, children }) => {
  const { colors } = useTheme();

  const mangoList = (
    <ul
      style={{
        padding: 0,
        listStyle: 'none',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}>
      {mangoes?.slice(1).map((mango) => (
        <li key={mango?._id} style={{ color: colors.BACKGROUND }}>
          {mango?.title}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="postMangoesWrapper">
      {mangoes?.length > 0 ? (
        <>
          <Typography.Text
            ellipsis
            style={{
              marginRight: 6,
              fontSize: 13,
              fontWeight: 500,
              color: colors.TEXT3,
            }}>
            {children}
            {mangoes[0]?.title}
          </Typography.Text>
          {mangoes?.length > 1 ? (
            <Popover
              content={mangoList}
              overlayInnerStyle={overlayInnerStyle}
              color="rgba(0, 0, 0, 0.8)">
              <Typography.Text
                ellipsis
                style={{
                  ...popoverTitleStyle,
                  color: colors.TEXT3,
                  backgroundColor: colors.BORDER,
                }}
                onClick={(e) => e.stopPropagation()}>
                +{mangoes?.length - 1}
              </Typography.Text>
            </Popover>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default MangoTitle;
