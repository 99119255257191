import { IProfileDetailsResponse } from '../screens/UserProfile/types';
import { IDisabledNotificationStates } from '../types/baseTypes';
import {
  ICreatorDetailsResponse,
  ISubscription,
  IUserDetails,
  SubscriberDetailsWithActiveMangoes,
} from '../types/userTypes';
import API from './';

export default abstract class UserAPI {
  static getLatestUserDetails = () =>
    API.get<IUserDetails>('/get-user-details?detailsrestricted=true');

  static savePersonalDetails = (
    name: string,
    isEmailSignup: boolean,
    newData: string,
  ) => {
    const req: any = {
      profilePicUrl:
        'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png',
      name,
    };
    if (isEmailSignup) {
      req.phone = parseInt(newData, 10);
    } else {
      req.email = newData;
    }
    return API.post('/update-personal-details-onboarding', req);
  };

  static registerUser = (
    name: string,
    phone: string,
    email: string,
    country: string,
    resend?: boolean,
  ) => {
    const req: any = {
      profilePicUrl:
        'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png',
      name,
    };
    req.phone = parseInt(phone, 10);
    req.email = email;
    req.country = country;
    if (resend) {
      req.resend = resend;
    }
    return API.post('/signup-get-otp', req);
  };

  static getProfileDetails = async (userId: string) =>
    API.get<IProfileDetailsResponse>(`/user/${userId}`);

  static blockUser = async (userId: string, block = true) =>
    API.post('/community/block', {
      fan: userId,
      block,
    });

  static updateAccountDetails = (
    updateAccountDetailsData: Partial<IUserDetails | any>,
  ) => API.post('/account-details-update', updateAccountDetailsData);

  static uploadIntroVideo = (data: FormData) =>
    API.post(`/upload-intro-video`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  static getAllSubscriptions = () =>
    API.get<ISubscription[]>('/get-all-active-subscription');

  static cancelSubscription = (subscriptionId: string) =>
    API.post(`/cancel-subscription/${subscriptionId}`);

  static deactivateAccount = (type: 'deactivate' | 'delete') =>
    API.post('/deactivate-account', { deleteRequest: type === 'delete' });

  static getChangeNumberOTP = (phone: string) =>
    API.post('/change-phone-no-getotp', {
      phone: phone.toString().trim().toLowerCase(),
    });

  static getChangeEmailOTP = (email: string) =>
    API.post('/change-email-getotp', { email });

  static changeNumber = (mobile: string, inputOtp: string) =>
    API.post('/change-phone-no', {
      phone: mobile.toString().trim().toLowerCase(),
      otp: inputOtp,
    });

  static changeEmail = (email: string, inputOtp: string) =>
    API.post('/change-email', {
      email: email.trim().toLowerCase(),
      otp: inputOtp,
    });

  static getDisabledNotificationStatus = (notificationType: string) =>
    API.get<IDisabledNotificationStates>(
      `/disabled-notifications-status?notificationType=${notificationType}`,
    );

  static disableNotificationStatus = (
    notificationType: string,
    disabledValues: IDisabledNotificationStates,
  ) =>
    API.post(`/disable-notifications`, {
      notificationType,
      ...disabledValues,
    });

  static getCreatorsDetails = async (
    creatorId: string,
    showHiddenMangoes?: boolean,
    showFreeMangoes?: boolean,
  ) => {
    const query = new URLSearchParams({
      subscriber_count: 'true',
      ...(showHiddenMangoes && { showhiddenmangoes: 'true' }),
      ...(showFreeMangoes && { showzerocostmango: 'true' }),
    });

    return API.get<ICreatorDetailsResponse>(
      `/get-creator-details/${creatorId}?${query}`,
    );
  };

  static getCreatorsDetailsBySlug = async (slug: string) =>
    API.get<ICreatorDetailsResponse>(
      `/get-creator-details-slug/${slug}?subscriber_count=true`,
    );

  static getCreatorMangoes = async (
    creatorId: string,
    showHiddenMangoes?: boolean,
    showFreeMangoes?: boolean,
  ) => {
    const query = new URLSearchParams({
      subscriber_count: 'false',
      ...(showHiddenMangoes && { showhiddenmangoes: 'true' }),
      ...(showFreeMangoes && { showzerocostmango: 'true' }),
    });

    return API.get<ICreatorDetailsResponse>(
      `/get-creator-details/${creatorId}?${query}`,
    );
  };

  static getSubscriberDetailsWithActiveMango = (creatorId: string) =>
    API.get<SubscriberDetailsWithActiveMangoes>(
      `/get-subcriber-details-with-active-mango?creatorId=${creatorId}`,
    );

  static updateProfileViews = async (creator: string, userId: string | null) =>
    API.post('/analytics/page-view', {
      creator,
      isCreatorProfilePageView: true,
      loggedInUserId: userId,
    });

  static notifyUserOnMango = (email: string, creatorId: string) =>
    API.post<any>('/notify-me-new-mango', {
      fanEmail: email,
      creatorId,
    });

  static sendEmailVerificationLink = () =>
    API.post<any>('/account/email-verify');

  static verifyEmailToken = (token: string) =>
    API.post<any>(`/account/email-verify/complete?token=${token}`);
}
