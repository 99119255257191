import './FeedBannersSlider.scss';

import React, { memo, useEffect, useState } from 'react';

import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import Slider from 'react-slick';

import FeedAPI from '../../../../api/FeedAPI';
import CustomImage from '../../../../components/CustomImage/CustomImage';
import { IFeedBanner } from '../../../../types/feedTypes';

const buttonStyle: React.CSSProperties = {
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '2rem', // 12 in tailwind (3rem)
  height: '2rem',
  border: 'none',
  borderRadius: '9999px', // full rounded corners
  backgroundColor: 'rgba(0, 0, 0, 0.75)', // bg-black/[0.75]
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  fontSize: '1.5rem', // text-4xl
  color: 'white',
  transition: 'background-color 0.3s', // smooth hover effect
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // shadow-xs
};

const ArrowNext = (props: any) => {
  return (
    <button
      type="button"
      style={{
        ...buttonStyle,
        right: '1rem',
      }}
      onClick={props.onClick}>
      <MdOutlineKeyboardArrowRight style={{ flexShrink: 0 }} />
    </button>
  );
};

const ArrowPrev = (props: any) => {
  return (
    <button
      type="button"
      style={{
        ...buttonStyle,
        left: '1rem',
      }}
      onClick={props.onClick}>
      <MdOutlineKeyboardArrowLeft style={{ flexShrink: 0 }} />
    </button>
  );
};

const FeedBannersSlider: React.FC = () => {
  const [banners, setBanners] = useState<IFeedBanner[]>([]);

  const loadActiveFeedBanners = async () => {
    const errorMsg = 'Failed to load feed banners';

    try {
      const resp = await FeedAPI.getActiveFeedBanners();
      if (resp.status === 200) {
        setBanners(resp.data.result);
        // NOTE: Don't show error message to user as this is not mandatory to have active feed banners
      } else {
        console.log(errorMsg, resp);
      }
    } catch (error) {
      console.log(errorMsg, error);
    }
  };

  useEffect(() => {
    loadActiveFeedBanners();
  }, []);

  if (banners.length === 0) {
    return null;
  }

  if (banners.length === 1) {
    return (
      <>
        {banners.map((banner) => (
          <div
            key={banner._id}
            className="feed__banner"
            onClick={() => {
              if (banner.url) {
                window.open(banner.url, '_blank');
              }
            }}>
            <CustomImage src={banner.thumbnail} />
          </div>
        ))}
      </>
    );
  }

  return (
    <Slider
      arrows
      dots={false}
      infinite
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay
      // autoplaySpeed={7000}
      nextArrow={<ArrowNext />}
      prevArrow={<ArrowPrev />}
      className="feed-banners-slider">
      {banners.map((banner) => (
        <div
          key={banner._id}
          className="feed__banner"
          onClick={() => {
            if (banner.url) {
              window.open(banner.url, '_blank');
            }
          }}>
          <CustomImage src={banner.thumbnail} />
        </div>
      ))}
    </Slider>
  );
};

export default memo(FeedBannersSlider);
