import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Avatar, Button, Space, Typography } from 'antd';
import { IoClose } from 'react-icons/io5';
import {
  Mention,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions';
import TMIcon from 'tm-icons-library';

import FeedAPI from '../../../../api/FeedAPI';
import CustomImagePreview from '../../../../components/CustomImagePreview/CustomImagePreview';
import Loader from '../../../../components/Loader';
import { useTheme } from '../../../../context/ThemeProvider';
import showAppError from '../../../../shared/error';
import { uploadFiles, uploadFilesHandler } from '../../../../shared/file';
import { useAppSelector } from '../../../../shared/hooks';
import { ICommentSelected } from '../../../../types/commentTypes';

const ICON_SIZE = 20;

interface Props {
  isSending: boolean;
  postId: string;
  onComment: (comment: string, file?: File) => void;
  reply: ICommentSelected;
  onReplyClose: () => void;
}

export interface PostCommentInputRefProps {
  clearInput: () => void;
  focusInput: () => void;
}

const PostCommentInput: React.ForwardRefRenderFunction<
  PostCommentInputRefProps,
  Props
> = ({ isSending, postId, onComment, reply, onReplyClose }, ref) => {
  const { isTagMango } = useAppSelector((state) => state.app);
  const { profilePic } = useAppSelector((state) => state.user);

  const { colors: COLORS } = useTheme();

  const [comment, setComment] = useState('');
  const [file, setFile] = useState<File>();
  const [emptySearchResults, setEmptySearchResults] = useState<string[]>([]);

  const suggestionContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<React.Component<MentionsInputProps>>(null);

  const fileUrl = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return '';
  }, [file]);

  const onReset = () => {
    setComment('');
    setFile(undefined);
    // setMentions([]);
    setEmptySearchResults([]);
  };

  const postComment = () => {
    const commentText = comment.trim();

    if (!commentText) return;

    // if (!commentText && !file) {
    //   // message.warning('Please write a comment or upload a file!');
    //   return;
    // }

    onComment(commentText, file);
  };

  const updateCommentValue = (
    _: { target: { value: string } },
    newValue: string,
  ) => {
    setComment(newValue);
  };

  const onMentionSearch = async (
    searchText: string,
    callback: (arg0: { id: string; display: string }[]) => void,
  ) => {
    if (searchText.length === 0) {
      callback([]);
      return;
    }

    if (emptySearchResults.find((item) => searchText.startsWith(item))) {
      callback([]);
      return;
    }

    try {
      const resp = await FeedAPI.searchMentionedUser(searchText, postId);
      if (resp.status === 200) {
        if (resp.data.result.length === 0) {
          setEmptySearchResults((prev) => [...prev, searchText]);
        }
        callback(
          resp.data.result.map((user) => ({
            id: user._id,
            display: user.name,
            profilePicUrl: user.profilePicUrl,
          })),
        );
      } else {
        showAppError(resp.data);
      }
    } catch (error) {
      showAppError(error);
    }
  };

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      if (inputRef.current) {
      }
    },
    clearInput: () => {
      onReset();
    },
  }));

  useEffect(() => {
    if (reply.userId) {
      setComment(`{{${reply.userId}#${reply.userName}}} `);
    } else {
      setComment('');
    }
  }, [reply]);

  return (
    <div className="postInputv2Container">
      {reply.userId ? (
        <div className="postInputv2__reply">
          <div className="postInputv2__reply__content">
            <Typography.Text type="secondary">Replying to</Typography.Text>

            <Typography.Title level={5}>{reply.userName}</Typography.Title>
          </div>

          <div className="postInputv2__reply__close">
            <Button size="small" type="text" onClick={onReplyClose}>
              <IoClose size={24} color={COLORS.TEXT3} />
            </Button>
          </div>
        </div>
      ) : null}

      {file ? (
        <div className="post-input-file-preview">
          <CustomImagePreview
            fileUrl={fileUrl}
            wrapperStyle={{ flexShrink: 0, borderRadius: 8 }}
          />
          <TMIcon
            name="close-circle"
            size={ICON_SIZE}
            color={COLORS.ICON}
            onClick={() => setFile(undefined)}
            className="post-input-file-preview__close-btn"
            style={{ flexShrink: 0, cursor: 'pointer' }}
          />
        </div>
      ) : null}

      <div className="postInputv2__mentions" ref={suggestionContainerRef} />

      <div className="postInputv2Wrapper">
        <Avatar
          src={profilePic}
          alt="Author"
          size={36}
          style={{
            flexShrink: 0,
          }}
        />

        <MentionsInput
          id={`input${postId}${reply?.commentId ?? ''}`}
          className="postInputv2"
          style={{ width: `calc(100% - ${isTagMango ? 80 : 112}px)` }}
          placeholder="Write a comment..."
          singleLine={false}
          allowSpaceInQuery
          ref={inputRef}
          suggestionsPortalHost={suggestionContainerRef.current ?? undefined}
          value={comment}
          onChange={updateCommentValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              postComment();
            }
          }}
          disabled={isSending}>
          <Mention
            trigger="@"
            data={onMentionSearch}
            markup="{{__id__#__display__}}"
            displayTransform={(_: string, display: string) => `@${display}`}
            renderSuggestion={(
              suggestion: SuggestionDataItem,
              _: string,
              highlightedDisplay: React.ReactNode,
            ) => (
              <div className="mention-suggestion">
                <Space size={8}>
                  <Avatar
                    /* @ts-ignore */
                    src={suggestion.profilePicUrl}
                    alt="image"
                    size={32}
                    style={{ flexShrink: 0 }}
                  />
                  <Typography.Text className="mention-suggestion__name">
                    {highlightedDisplay}
                  </Typography.Text>
                </Space>
              </div>
            )}
          />
        </MentionsInput>

        <div className="post-input-wrapper__actions">
          {isTagMango ? null : (
            // Only for WL platforms
            <TMIcon
              name="camera"
              size={ICON_SIZE}
              color={COLORS.ICON}
              className="post-input-wrapper__actions__icon-btn"
              onClick={
                isSending
                  ? undefined
                  : () =>
                      uploadFilesHandler({
                        type: 'image',
                        onUpload: (files, type) => {
                          uploadFiles({
                            files,
                            type,
                            maxSizeUnit: 'MB',
                            maxSize: 1024, // 1GB
                            uploadCallback: (list) => {
                              setFile(list?.[0]);
                            },
                          });
                        },
                      })
              }
              style={isSending ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
            />
          )}

          {isSending ? (
            <Loader
              size="small"
              className="post-input-wrapper__actions__sending-loader"
            />
          ) : (
            <TMIcon
              name="send"
              size={ICON_SIZE}
              color={COLORS.ICON}
              className="post-input-wrapper__actions__icon-btn"
              onClick={postComment}
            />
          )}
        </div>

        {/* <Button
          disabled={sending}
          className="postInputv2__sendIcon"
          onClick={postComment}>
          {sending ? (
            <Loader size="small" />
          ) : (
            <MdSend size={20} color={colors.BACKGROUND} />
          )}
        </Button> */}
      </div>
    </div>
  );
};

export default forwardRef(PostCommentInput);
