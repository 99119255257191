import { message } from 'antd';
import { AxiosProgressEvent } from 'axios';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import FeedAPI from '../api/FeedAPI';
import showAppError from '../shared/error';
import { IProgressData, ITagsBase } from '../types/feedTypes';
import { AppThunk } from './store';

interface IPostState {
  loading: boolean;
  page: number;
  hasMore: boolean;
  posts: any[];
  refreshing: boolean;
}

interface ILikedByUsersModalState {
  visible: boolean;
  postId?: string;
  pollId?: string;
  commentId?: string;
}

interface IActivitySlice {
  selected: string | null;
  subscriptions: any[];
  filters: any;
  postTags: ITagsBase[];
  filteredMangoes: any[];
  sort: 'asc' | 'desc';
  postState: IPostState;
  progressData: IProgressData;
  isUserProfilePage?: boolean;
  likedByUsersModalState: ILikedByUsersModalState;
  // viewedPostIds: Set<string>;
  viewedPostIds: string[];
}

const initialState: IActivitySlice = {
  selected: '',
  subscriptions: [],
  postTags: [],
  filters: [
    { name: 'Image', active: false },
    { name: 'Video', active: false },
    { name: 'Audio', active: false },
    { name: 'Text', active: false },
  ],
  filteredMangoes: [],
  sort: 'asc',
  postState: {
    loading: false,
    page: 1,
    hasMore: true,
    posts: [],
    refreshing: false,
  },
  progressData: {
    loaded: 0,
    total: 100,
    message: '',
    showProgress: false,
    post: null,
  },
  isUserProfilePage: false,
  likedByUsersModalState: {
    visible: false,
  },
  // viewedPostIds: new Set<string>(),
  viewedPostIds: [],
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<string | null>) => {
      state.selected = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<any[]>) => {
      state.subscriptions = action.payload;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      Object.assign(state.filters, action.payload);
    },
    setFilteredMangoes: (state, action: PayloadAction<any[]>) => {
      state.filteredMangoes = action.payload;
    },
    setSort: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.sort = action.payload;
    },
    setPostTags: (state, action: PayloadAction<ITagsBase[]>) => {
      state.postTags = action.payload;
    },
    setPostState: (state, action: PayloadAction<Partial<IPostState>>) => {
      Object.assign(state.postState, action.payload);
    },
    setProgressData: (state, action: PayloadAction<Partial<IProgressData>>) => {
      Object.assign(state.progressData, action.payload);
    },
    setIsUserProfilePage: (state, action: PayloadAction<boolean>) => {
      state.isUserProfilePage = action.payload;
    },
    setLikedByUsersModalState: (
      state,
      action: PayloadAction<ILikedByUsersModalState>,
    ) => {
      Object.assign(state.likedByUsersModalState, action.payload);
    },
    // setViewedPostIds: (state, action: PayloadAction<string>) => {
    //   const newViewedPostIds = new Set(state.viewedPostIds);

    //   newViewedPostIds.add(action.payload);
    //   state.viewedPostIds = newViewedPostIds;
    // },
    setViewedPostIds: (state, action: PayloadAction<string>) => {
      const newViewedPostIds = [...state.viewedPostIds];

      // Added an extra check to avoid duplicate post view count increase
      if (!newViewedPostIds.includes(action.payload)) {
        newViewedPostIds.push(action.payload);
        state.viewedPostIds = newViewedPostIds;
      }
    },
    clearActivity: () => initialState,
    resetAllFilters: (state) => {
      const newState: Partial<IActivitySlice> = {
        filters: [
          { name: 'Image', active: false },
          { name: 'Video', active: false },
          { name: 'Audio', active: false },
          { name: 'Text', active: false },
        ],
        filteredMangoes: [],
        sort: 'asc',
        postTags: [],
      };
      Object.assign(state, newState);
    },
  },
});

export const {
  setSelected,
  setSubscriptions,
  setFilters,
  setFilteredMangoes,
  setSort,
  setPostTags,
  setPostState,
  setProgressData,
  setIsUserProfilePage,
  setLikedByUsersModalState,
  setViewedPostIds,
  clearActivity,
  resetAllFilters,
} = activitySlice.actions;

export const getPosts =
  (data: {
    pageCount: any;
    postList?: any[];
    hasMore?: boolean;
    loading?: boolean;
    currState?: IPostState;
    showUserPosts?: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const { isTagMango } = state.app;
    // const { type: userType } = state.user;
    const { selected, filters, filteredMangoes, sort, postState, postTags } =
      state.activity;
    const { token } = state.app;

    const { pageCount } = data;

    const postList = data.postList || state.activity.postState.posts;
    const hasMore = data.hasMore || state.activity.postState.hasMore;
    const loading = data.loading || state.activity.postState.loading;
    const currState = data.currState || state.activity.postState;
    const showUserPosts = data.showUserPosts || false;

    if (hasMore && !loading && token) {
      dispatch(setPostState({ ...currState, loading: true }));
      FeedAPI.getPosts(
        pageCount,
        (selected === 'all' || !isTagMango) &&
          // (userType === 'fan_completed' && isCommunityEnabled) ||
          // (userType !== 'fan_completed' && !isTagMango)) &&
          !showUserPosts
          ? ''
          : selected || '',
        filters
          .filter((filter: any) => filter.active)
          .map((filter: any) => filter.name.toLowerCase()),
        filteredMangoes.map((mango: any) => mango._id),
        sort,
        postTags.map((tag: any) => tag._id),
        '',
        showUserPosts,
      )
        .then((response: any) => {
          const res = response.data;
          let newState = { ...currState, loading: false, refreshing: false };

          if (!res.error) {
            if (res.result && res.result.length) {
              const updatedPost =
                pageCount > 1
                  ? [...(postList || []), ...res.result]
                  : [...res.result];
              newState = {
                ...newState,
                posts: updatedPost,
                page: pageCount + 1,
                hasMore: true,
              };
            } else {
              newState = {
                ...newState,
                posts: [...(postList || [])],
                hasMore: false,
              };
            }
          }

          dispatch(setPostState({ ...postState, ...newState }));
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(setPostState({ ...postState, loading: false }));
        });
    }
  };

export const removePost =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const {
      activity: { postState },
    } = getState();
    dispatch(
      setPostState({
        ...postState,
        posts: [...postState.posts].filter(({ _id }) => _id !== id),
      }),
    );
  };

export const updatePost =
  (post: any): AppThunk =>
  (dispatch, getState) => {
    const {
      activity: { postState },
    } = getState();
    const newPosts = [...postState.posts].map((prevPost) => {
      if (prevPost._id === post._id) {
        return {
          ...prevPost,
          ...post,
        };
      }

      return prevPost;
    });

    dispatch(
      setPostState({
        ...postState,
        posts: newPosts,
      }),
    );
  };

export const onRefresh = (): AppThunk => (dispatch, getState) => {
  const {
    activity: { postState, selected },
  } = getState();

  const newState = {
    posts: [],
    hasMore: true,
    page: 1,
    loading: false,
    refreshing: true,
  };

  dispatch(
    setPostState({
      ...postState,
      ...newState,
    }),
  );

  if (selected) {
    dispatch(
      getPosts({
        pageCount: 1,
        postList: [],
        hasMore: true,
        loading: false,
        currState: newState,
      }),
    );
  }
};

const handleProgress =
  (event: AxiosProgressEvent): AppThunk =>
  (dispatch, getState) => {
    const {
      activity: { progressData },
    } = getState();
    dispatch(
      setProgressData({
        ...progressData,
        loaded: event.loaded,
        total: event.total,
        message:
          event.loaded === event.total ? 'Processing' : progressData.message,
      }),
    );
  };

export const createActivityPost =
  (
    data: FormData,
    mangoes: any[],
    caption: string | undefined | null,
    contentType: string,
    onSucess?: () => void,
  ): AppThunk =>
  async (dispatch, getState) => {
    const { user: userDetails } = getState();

    try {
      dispatch(
        setProgressData({
          message: 'Uploading',
          loaded: 0,
          total: 100,
          showProgress: true,
          post: {
            mangoArr: mangoes,
            caption,
            creatorId: userDetails.id,
            imgUrl: userDetails.profilePic,
            name: userDetails.name,
            createdAt: new Date().toISOString(),
            contentType,
          },
        }),
      );
      const resp = await FeedAPI.createPost(data, (e) => {
        dispatch(handleProgress(e));
      });
      dispatch(setProgressData({ showProgress: false }));
      if (resp.status === 200) {
        dispatch(onRefresh());
        onSucess?.();
        message.success('Post created successfully');
      } else {
        showAppError(resp.data);
      }
    } catch (err) {
      dispatch(setProgressData({ showProgress: false }));
      showAppError(err);
    }
  };

export default activitySlice.reducer;
