import { IMango } from '../../types/feedTypes';
import {
  setIsCommunityEnabled,
  setIsGamificationEnabled,
  setUserTier,
} from '../appReducer';
import { setSubscribedMangoes as setAllSubscribedMangoes } from '../mangoReducer';
import { AppThunk } from '../store';
import { setSubscribedMangoes } from '../userReducer';

const updateUserTierDetails = (): AppThunk => async (dispatch, getState) => {
  const { hostMetadata } = getState().app;

  const userTier = hostMetadata.creator.userTier;

  dispatch(setUserTier(userTier));
};

const updateSubscribedMangoes =
  (mangoes: IMango[]): AppThunk =>
  async (dispatch, getState) => {
    const {
      hostMetadata: { gamifiedMangoes, creator, communityEnabledMangoes },
    } = getState().app;
    const userDetails = getState().user;

    if (gamifiedMangoes && gamifiedMangoes.length > 0) {
      if (
        userDetails.id === creator?._id ||
        userDetails.type === 'creator_restricted'
      ) {
        dispatch(setIsGamificationEnabled(true));
      } else if (mangoes && mangoes.length > 0) {
        const result = mangoes.filter((mango) =>
          gamifiedMangoes.includes(mango._id),
        );
        dispatch(setIsGamificationEnabled(result.length > 0));
      }
    }

    if (communityEnabledMangoes && communityEnabledMangoes.length > 0) {
      if (
        userDetails.id === creator?._id ||
        userDetails.type === 'creator_restricted'
      ) {
        dispatch(setIsCommunityEnabled(true));
      } else if (mangoes && mangoes.length > 0) {
        const result = mangoes.filter((mango) =>
          communityEnabledMangoes.includes(mango._id),
        );
        dispatch(setIsCommunityEnabled(result.length > 0));
      }
    }

    dispatch(setSubscribedMangoes(mangoes));
    dispatch(setAllSubscribedMangoes(mangoes));
  };

export { updateSubscribedMangoes, updateUserTierDetails };
