import axios, { AxiosProgressEvent } from 'axios';

import {
  IAssessmentCombinedResponse,
  IAssessmentDetailsResponse,
  IAssessmentSubmissionGetResponse,
  IAssessmentSubmissionResponse,
  IFetchedUserAssignment,
} from '../types/assessmentTypes';
import {
  ICourse,
  ICourseReview,
  ICoursesRequest,
  IModuleType,
  IProgressResponse,
} from '../types/courseTypes';
import {
  IQNA,
  IQNAReply,
  IQNAReplyCreate,
} from '../types/qnaTypes';
import API from './';

export default abstract class CourseAPI {
  static root = '/courses';

  static sendCertificateMail = (courseId: string, recipientId: string) =>
    API.post(`certificates/${courseId}/sendIndividual`, { recipientId });

  static getCourses = (data: ICoursesRequest) => {
    const {
      page,
      limit,
      creator,
      search,
      onlySubscribed,
      isPublished,
      excludeCompletedCourse,
      category,
      mango,
    } = data;

    const query = new URLSearchParams({
      page: String(page),
      limit: String(limit),
      ...(creator && { creator }),
      ...(search && { search }),
      ...(onlySubscribed && { onlySubscribed: 'true' }),
      ...(isPublished && { isPublished: 'true' }),
      ...(excludeCompletedCourse && { excludeCompletedCourse: 'true' }),
      ...(mango && { 'mango[]': mango }),
      ...(category && category !== 'all' && { status: category }),
    });

    return API.get<ICourse[]>(`${this.root}${query ? `?${query}` : ''}`);
  };

  static getPurchasedCourses = (page: number, limit: number) =>
    API.get<ICourse[]>(
      `/courses?isPublished=true&onlySubscribed=true&page=${page}&limit=${limit}`,
    );

  static getMyCourses = (userId: string, page: number, limit: number) =>
    API.get<ICourse[]>(
      `/courses?creator=${userId}&page=${page}&limit=${limit}`,
    );

  static getCoursesForFeed = (limit: number) =>
    API.get<ICourse[]>(
      `/courses?isPublished=true&limit=${limit}&onlySubscribed=true&excludeCompletedCourse=true`,
    );

  static getCourseById = (courseId: string) =>
    API.get<ICourse>(`/courses/${courseId}?withSubscriberCount=true`);

  static getCourseModules = (courseId: string) =>
    API.get<{
      mappedResponse: IModuleType[];
      paidCourse: boolean;
    }>(`/v2/courses/${courseId}/modules`);

  static updateChapterProgress = (
    currentChapter: string,
    currentChapterProgress: number,
    nextChapter: string | null,
    course: string,
    module: string,
    progress: number,
    moduleProgress: number,
  ) =>
    API.put<IProgressResponse>(`progress`, {
      currentChapter,
      currentChapterProgress,
      nextChapter,
      course,
      module,
      progress,
      moduleProgress,
    });

  static deleteCourse = (courseId: string) =>
    API.delete<{}>(`/courses/${courseId}`);

  static createQnA = (
    chapterId: string,
    data: any,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
  ) => {
    const headers = {
      ...axios.defaults.headers.common,
      'Content-Type': 'multipart/form-data',
    };
    return API.post(`/qnas/${chapterId}`, data, {
      headers,
      onUploadProgress,
    });
  };

  static createReply = (
    chapterId: string,
    qnaId: string,
    data: any,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
  ) => {
    const headers = {
      ...axios.defaults.headers.common,
      'Content-Type': 'multipart/form-data',
    };
    return API.post<IQNAReplyCreate>(`/qnas/${chapterId}/${qnaId}`, data, {
      headers,
      onUploadProgress,
    });
  };

  static getQnaList = (chapterId: string, page: number, limit: number) =>
    API.get<IQNA[]>(`/qnas/${chapterId}?page=${page}&limit=${limit}`);

  static getCommentsList = (chapterId: string, page: number, limit: number) =>
    API.get<IQNA[]>(
      `/qnas/${chapterId}?page=${page}&limit=${limit}&type=comment`,
    );

  static getQnaReplyList = (qnaId: string, page: number, limit: number) =>
    API.get<IQNAReply[]>(`/qnareplies/${qnaId}?page=${page}&limit=${limit}`);

  static removeQNA = (qnaId: string) => API.delete<any>(`/qnas/${qnaId}`);

  static getAssignmentDetails = (assignmentId: string) =>
    API.get<IAssessmentDetailsResponse>(`/assignment/${assignmentId}/details`);

  static getAssignmentCombined = (assignmentId: string) =>
    API.get<IAssessmentCombinedResponse>(
      `/assignment-combined/${assignmentId}`,
    );

  static getAssignmentUserSubmissions = (assignmentId: string) =>
    API.get<IAssessmentSubmissionGetResponse>(
      `/usersubmissions/${assignmentId}`,
    );

  static assignmentUserSubmission = (questionId: string, data: any) =>
    API.post<IAssessmentSubmissionResponse>(
      `/usersubmission/${questionId}`,
      data,
    );

  static assignmentUserSubmissionV2 = (questionId: string, data: any) =>
    API.post<IAssessmentSubmissionResponse>(
      `/v2/usersubmission/${questionId}`,
      data,
    );

  static updateAssignmentUserSubmissionV2 = (questionId: string, data: any) =>
    API.put<IAssessmentSubmissionResponse>(
      `/v2/usersubmission/${questionId}`,
      data,
    );

  static startAssignment = (assignmentId: string, retake: boolean) =>
    API.post<IFetchedUserAssignment>(
      `/assignment/${assignmentId}/${retake ? 'retake' : 'start'}`,
    );

  static submitAssignment = (assignmentId: string) =>
    API.post<IFetchedUserAssignment>(`/assignment/${assignmentId}/finish`);

  static reviewCourse = (courseId: string, rating: number, review?: string) =>
    API.post(`/course/review/${courseId}`, { rating, review });

  static getCourseReview = (courseId: string) =>
    API.get<ICourseReview | null>(`/course/review/${courseId}`);
}
