import React, { useCallback, useEffect, useState } from 'react';

import { message, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';
import { IoChevronDown } from 'react-icons/io5';

import CourseAPI from '../../api/CourseAPI';
import SearchBar from '../../components/Header/SearchBar';
import MangoSelectionModal from '../../components/MangoSelectionModal/MangoSelectionModal';
import { useTheme } from '../../context/ThemeProvider';
import showAppError from '../../shared/error';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import {
  CourseCategory,
  ICoursesFilters,
  ICoursesState,
} from '../../types/courseTypes';
import { ROUTES } from '../../types/routes';
import { ISubscription } from '../../types/userTypes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';
import CourseListCard from './CourseListCard/CourseListCard';
import NoCoursesContent from './NoCoursesContent';

const COURSE_FILTER_OPTIONS: Record<CourseCategory, string> = {
  all: 'All',
  'in-progress': 'In Progress',
  completed: 'Completed',
  expired: 'Expired',
  paid: 'Paid',
};

const initialFilters: ICoursesFilters = {
  search: '',
  category: 'all',
  mango: null,
};

const initialState: ICoursesState = {
  loading: false,
  hasMore: true,
  courses: [],
  page: 1,
  limit: 2,
};

interface Props {
  userId: string | null;
  subscriberMangoes?: ISubscription[];
}

const PurchasedCourses: React.FC<Props> = ({ userId }) => {
  const navigate = useAppNavigate();

  const { colors } = useTheme();

  const { hostMetadata } = useAppSelector((state) => state.app);
  const { subscribedMangoes } = useAppSelector((state) => state.user);

  const [filters, setFilters] = useState<ICoursesFilters>(initialFilters);
  const [showMangoModal, setShowMangoModal] = useState(false);
  const [state, setState] = useState<ICoursesState>(initialState);

  const onChangeFilters = <Key extends keyof ICoursesFilters>(
    key: Key,
    value: ICoursesFilters[Key],
  ) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const loadCourses = async (
    page: number = 1,
    currState: ICoursesState = state,
  ) => {
    if (currState.loading || !currState.hasMore) return;

    const errorMsg = 'Failed to load courses.';

    const newState = { ...initialState, ...currState };

    if (page === 1) {
      newState.courses = [];
      newState.page = 1;
      newState.hasMore = true;
    }

    newState.loading = true;

    setState({ ...newState });

    try {
      const resp = await CourseAPI.getCourses({
        page,
        limit: newState.limit,
        search: filters.search,
        isPublished: true,
        onlySubscribed: true,
        category: filters.category,
        mango: filters.mango?._id,
      });
      if (resp.status === 200) {
        const newCourses = [...resp.data.result];

        newState.courses = [...newState.courses, ...newCourses];

        newState.hasMore = newCourses.length >= newState.limit;

        if (newState.hasMore) {
          newState.page += 1;
        }
      } else {
        showAppError(resp.data, errorMsg);
      }
    } catch (error: any) {
      showAppError(error, errorMsg);
    } finally {
      newState.loading = false;
      setState({ ...newState });
    }
  };

  useEffect(() => {
    loadCourses(1, initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, userId]);

  const renderTagItem = useCallback(
    (
      value: string,
      key: string,
      onClick: () => void,
      isSelected: boolean,
      hasArrow: boolean = false,
    ) => (
      <div
        key={key}
        onClick={onClick}
        className={`tags-filter__tag ${isSelected ? 'active' : ''}`}>
        <Typography.Text>{value}</Typography.Text>
        {hasArrow && (
          <IoChevronDown
            size={18}
            color={isSelected ? colors.PRIMARY : colors.ICON}
          />
        )}
      </div>
    ),
    [colors.ICON, colors.PRIMARY],
  );

  return (
    <>
      <SearchBar
        onDebounce={(value) => {
          onChangeFilters('search', value);
        }}
        placeholder="Search"
        style={{
          width: '100%',
          borderBottomWidth: 1,
          borderColor: colors.BORDER,
        }}
      />

      <div className="tags-filter__container" style={{ borderTop: 'none' }}>
        <div className="tags-filter__wrapper">
          {Object.entries(COURSE_FILTER_OPTIONS).map(([key, value]) =>
            renderTagItem(
              value,
              key,
              () => onChangeFilters('category', key as CourseCategory),
              filters.category === key,
            ),
          )}

          {renderTagItem(
            filters.mango
              ? filters.mango.title?.toTitleCase()
              : hostMetadata.offeringTitle.toTitleCase(),
            'mango',
            () => {
              setShowMangoModal(true);
            },
            Boolean(filters.mango),
            true,
          )}
        </div>
      </div>

      <div className="courses__content">
        <EasyVirtualizedScroller
          useParentScrollElement
          hasMore={state.hasMore}
          onLoadMore={() => loadCourses(state.page)}>
          {state.courses.length > 0
            ? state.courses.map((item) => (
                <CourseListCard
                  key={item._id}
                  onPress={() => {
                    if (
                      item.courseValidity &&
                      item.courseValidity.status === 'Expired'
                    )
                      message.error('Course validity has expired');
                    else {
                      navigate(ROUTES.COURSE_OVERVIEW, {
                        courseId: item._id,
                      });
                    }
                  }}
                  type="purchased"
                  courseExpired={
                    item.courseValidity &&
                    item.courseValidity.status === 'Expired'
                  }
                  title={item.title}
                  coverImage={item.coverImage}
                  mangoes={item.mangoArr}
                  totalProgress={
                    typeof item.progress === 'number'
                      ? item.progress
                      : item.progress.progress || 0
                  }
                  totalLectures={item.numberOfChapters}
                  // paid course
                  purchaseRequired={item.paidCourse ?? false}
                  previewChapters={item.freeChapterCount || 0}
                  mangoToSell={item.mangoTosell}
                />
              ))
            : null}

          <NoCoursesContent userId={userId} state={state} />
        </EasyVirtualizedScroller>
      </div>

      <MangoSelectionModal
        type="single"
        show={showMangoModal}
        closeModal={() => setShowMangoModal(false)}
        selectedMangoes={filters.mango ? [filters.mango] : []}
        mangoes={subscribedMangoes}
        handleSave={(mangoes) => {
          if (mangoes.length === 0) {
            setFilters({ ...filters, mango: null });
          } else {
            setFilters({ ...filters, mango: mangoes[0] });
          }
          setShowMangoModal(false);
        }}
        noMangoComponent={
          <NoDataFound
            title={`No ${hostMetadata.offeringTitles} found`}
            // subTitle={`You have not subscribed to any ${hostMetadata.offeringTitles} yet`}
          />
        }
        customTitle="Which courses you want to see?"
        customDescription={`Select a ${hostMetadata.offeringTitle} whose courses you want to see`}
      />
    </>
  );
};

export default React.memo(PurchasedCourses);
