import React from 'react';

import { Typography } from 'antd';
import Linkify from 'react-linkify';

interface Props {
  caption: string;
  maxLines: number;
  onCaptionPressed?: () => void;
  style?: React.CSSProperties;
}

const PostContent: React.FC<Props> = ({
  caption,
  maxLines,
  onCaptionPressed,
  style,
}) => (
  <div className="creatorPostContentWrapper" onClick={onCaptionPressed}>
    <Linkify>
      <Typography.Paragraph
        className="postContent"
        ellipsis={{
          rows: maxLines,
          expandable: true,
          symbol: 'See more',
        }}
        style={style}>
        {caption}
      </Typography.Paragraph>
    </Linkify>
  </div>
);

export default PostContent;
