import React, { memo } from 'react';

import { useTheme } from '../../context/ThemeProvider';
import { ROUTES } from '../../types/routes';

const contentMap = {
  login: {
    path: ROUTES.LOGIN,
    title: 'Already have an account?',
    action: 'Login',
  },
  signup: {
    path: ROUTES.SIGNUPCREATOR,
    title: 'Not registered yet?',
    action: 'Sign Up',
  },
};

interface Props {
  action: 'login' | 'signup';
  style?: React.CSSProperties;
}

const LoginOrSignupBottomAction: React.FC<Props> = ({ action, style }) => {
  const { colors: COLORS } = useTheme();

  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: 12,
        color: COLORS.TEXT2,
        ...style,
      }}>
      <span>
        {contentMap[action].title}{' '}
        <a
          style={{ fontSize: 12, fontWeight: 500, color: COLORS.BLUE }}
          href={contentMap[action].path}>
          {contentMap[action].action}
        </a>
      </span>
    </div>
  );
};

export default memo(LoginOrSignupBottomAction);
